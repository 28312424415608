import { Button, Center, HStack, Input, VStack, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

interface AuthModalProps {
    isOpen: boolean;
    onAuthenticate: (password: string) => void;
    title: string;
    message: string;
}

const Auth: React.FC<AuthModalProps> = ({ isOpen, onAuthenticate, title, message }) => {
    const { t } = useTranslation();
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handlePasswordSubmit = () => {
        onAuthenticate(password);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handlePasswordSubmit();
        }
    };

    return (
        <Center width={'100%'} minHeight={'50vh'}>
            <VStack align="center" spacing={4} bgColor={'white'} p={{base: 6, lg:10}} borderRadius={'3xl'} width={{base: "100%", md:"50%"}} boxShadow={'xl'}>
                <Text>{message}</Text>
                <HStack width={"100%"}>
                    <Input
                        type={showPassword ? 'text' : 'password'}
                        placeholder={t('rsvpForm.PasswordPlaceholder')}
                        value={password}
                        width={"100%"}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyPress={handleInputKeyPress}
                    />
                    <Button size="md" onClick={togglePasswordVisibility}>
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </Button>
                </HStack>
                <Button colorScheme="teal" onClick={handlePasswordSubmit}>
                    {t('rsvpForm.Submit')}
                </Button>
            </VStack>
        </Center>
    );
};

export default Auth;
