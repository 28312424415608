import { Button, Center, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spinner, Stack, Text, VStack } from "@chakra-ui/react";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { HotelInfoType } from "../types";
import PriceBox from "./PriceBox";
import PricesInputFields from "./PricesInputFields";
import useHotelPriceSearch, { Rate } from "./useHotelPriceSearch";

interface PricesModalProps {
  hotel: HotelInfoType;
}

const PricesModal: FC<PricesModalProps> = ({ hotel }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { result, loading, error, fetchData } = useHotelPriceSearch();
  const { t } = useTranslation();

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const onSearch = (searchParams: {
    hotelId: string;
    checkInDate: string;
    checkOutDate: string;
    adults: number;
    rooms: number;
  }) => {
    fetchData(searchParams);
  };

  return (
    <Stack>
      <Button onClick={openModal}>{t('hotels.hotelInfo.pricesModal.ShowPrices')}</Button>
      <Modal isOpen={isOpen} onClose={closeModal} size={{base: 'sm', md: 'lg'}}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Hotel Information</ModalHeader>
          <ModalCloseButton />
          <ModalBody minH={'50vh'}>
            <PricesInputFields onSearch={onSearch} hotel={hotel} />
            {loading && <Center minH={'30vh'}><Spinner />
              <Text></Text></Center>}
            {error && <Text>Error: {error.message}</Text>}
            {result && (
                <VStack my={'3'}>
                  <Link href={`https://www.tripadvisor.com/${hotel.id}`} isExternal>{t('hotels.hotelInfo.pricesModal.GoToTripadvisor')}</Link>
                  {result?.rates?.map((rate: Rate ) => (
                    <PriceBox webpageURL={rate.name as string} price={rate.rate} currency={"$"} key={rate.code}/>
                  ))}
                </VStack>
            )}
            {(result !== null && result?.rates?.length=== 0) && !loading && !error && <Text>{t('hotels.hotelInfo.pricesModal.NoPrices')}</Text>}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default PricesModal;
