import { Box, Stack, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import RestaurantsSection from '../RestaurantsSection/RestaurantsSection';

const RecommendedLunch = () => {
    const { t } = useTranslation();


    const lunchAndDinnerCheap = [
        'Café Bar Uni',
        "The Breen's Tavern",
        'A Pulpeira de Melide',
        'A Taberna de Cunqueiro',
        'Caveira',
    ];

    const lunchAndDinnerExpensive = [
        'El de Alberto',
        'Restaurante Árbore da Veira',
        'Restaurante Peculiar',
        'Portofino',
        'La Sarten de Coruña',
        'O Lagar da Estrella',
        'Samaná',
    ];

    return (
        <RestaurantsSection title={t('restaurants.LunchDinner')}>
            <Stack spacing={8} justify="center" direction={{ base: 'column', md: 'row' }} width={'100%'}>
                <Box bg="white" p={{ base: 0, lg: 4 }} textAlign={'center'} width={{ base: "100%", md: '50%' }}>
                    <Text fontWeight="bold" fontSize="lg">
                        {t('restaurants.PriceRange1')}
                    </Text>
                    <VStack>
                        {lunchAndDinnerCheap.map((place, index) => (
                            <Text key={index}>{place}</Text>
                        ))}
                    </VStack>
                </Box>
                <Box bg="white" p={{ base: 0, lg: 4 }} textAlign={'center'} width={{ base: "100%", md: '50%' }}>
                    <Text fontWeight="semibold" fontSize="md">
                        {t('restaurants.PriceRange2')}
                    </Text>
                    <VStack>
                        {lunchAndDinnerExpensive.map((place, index) => (
                            <Text key={index}>{place}</Text>
                        ))}
                    </VStack>
                    <Text mt={2} fontWeight={'bold'}>{t('restaurants.ReservationTips')}</Text>
                </Box>
            </Stack>
        </RestaurantsSection>
    );
};

export default RecommendedLunch;
