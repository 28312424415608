import { Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import RestaurantsSection from './RestaurantsSection/RestaurantsSection';

const TypicalFood = () => {
    const { t } = useTranslation();

    const typicalFoodItems = [
        { name: 'Pulpo á feira', description: t('restaurants.typicalFood.PulpoFeiraDesc') },
        { name: 'Pimientos de Padrón', description: t('restaurants.typicalFood.PimientosPadronDesc') },
        { name: 'Zamburiñas', description: t('restaurants.typicalFood.ZamburinasDesc') },
        { name: 'Mejillones', description: t('restaurants.typicalFood.MejillonesDesc') },
        { name: 'Almejas / Berberechos', description: t('restaurants.typicalFood.AlmejasDesc') },
        { name: 'Navajas', description: t('restaurants.typicalFood.NavajasDesc') },
        { name: 'Calamares', description: t('restaurants.typicalFood.CalamaresDesc') },
        { name: 'Chipirones', description: t('restaurants.typicalFood.ChipironesDesc') },
        { name: 'Chorizo Criollo', description: t('restaurants.typicalFood.ChorizoCriolloDesc') },
        { name: 'Raxo', description: t('restaurants.typicalFood.RaxoDesc') },
        { name: 'Zorza', description: t('restaurants.typicalFood.ZorzaDesc') },
        { name: 'Tortilla de Patatas', description: t('restaurants.typicalFood.TortillaPatatasDesc') },
        { name: 'Croquetas', description: t('restaurants.typicalFood.CroquetasDesc') },
    ];

    return (
        <RestaurantsSection title={t('restaurants.typicalFood.TypicalFood')}>
            <VStack textAlign={'center'} spacing={4}>
                {typicalFoodItems.map((item, index) => (
                    <VStack key={index} pt={2} spacing={'1px'}>
                        <Text fontWeight="semibold" fontSize={'lg'}>{item.name}</Text>
                        <Text fontSize={'sm'}>{item.description}</Text>
                    </VStack>
                ))}
            </VStack>
        </RestaurantsSection>
    );
};

export default TypicalFood;
