import { Box, Button, Center, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const ConfirmAttendance: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const redirectToRSVP = () => {
        navigate('/rsvp');
    };

    return (
        <Center width={'100%'} justifyContent={'center'} my={10}>
            <Box
                p={{ base: 4, md: 6 }}
                mx={{ base: 4, md: 10 }}
                mt={4}
                textAlign={'center'}
                boxShadow={'md'}
                borderRadius={'lg'}
                bg="white"
                width={{ base: '100%', md: '50%' }}
                borderWidth="1px"
                borderColor="gray.200"
                _hover={{ boxShadow: 'xl' }}
            >
                <Text fontSize={{base: 'lg', md:"xl"}} mb={4}>
                    {t('homepage.confirmAttendance.Message')}
                </Text>
                <Button
                    colorScheme="teal"
                    size="lg"
                    onClick={() => {
                        redirectToRSVP();
                    }}
                >
                    {t('homepage.confirmAttendance.Button')}
                </Button>
            </Box>
        </Center>
    );
};

export default ConfirmAttendance;
