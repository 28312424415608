import { Box, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import RestaurantsSection from '../RestaurantsSection/RestaurantsSection';

const RecommendedBreakfast = () => {
    const { t } = useTranslation();

    const breakfastAndCoffee = [
        'Miss Maruja',
        'Santa Clara',
        'Manolo Bakes',
        'Waco',
        'Astro Café (Takeaway only)',
    ];
    return (
        <RestaurantsSection title={t('restaurants.BreakfastCoffee')}>
            <Box justifyContent={'center'}>
                <VStack>
                    {breakfastAndCoffee.map((place, index) => (
                        <Text key={index}>{place}</Text>
                    ))}
                </VStack>
            </Box>
        </RestaurantsSection>
    );
};

export default RecommendedBreakfast;
