import { Box, Divider, Heading, ListItem, Text, UnorderedList, VStack, Wrap } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import RecommendationsBox from './RecommendationsBox';

const Flights = () => {
  const { t } = useTranslation();

  const flightRecommendations = [
    {
      title: t('flights.ToMadrid'),
      options: [
        {
          details: [
            t('flights.ThenTrainToCoruna'),
            <strong>{t('flights.Or')}</strong>,
            t('flights.ThenFlightToCorunaM'),
          ],
        },
      ],
    },
    {
      title: t('flights.ToPorto'),
      options: [
        {
          details: [t('flights.ThenBusToCoruna')],
        },
      ],
    },
    {
      title: t('flights.ToBarcelona'),
      options: [
        {
          details: [t('flights.ThenFlightToCorunaB')],
        },
      ],
    },
    {
      title: t('flights.ToCoruna'),
      options: [
        {
          details: [t('flights.MostExpensiveOption')],
        },
      ],
    },
    {
      title: t('flights.ToSantiago'),
      options: [
        {
          details: [t('flights.ThenTrainToCorunaFromSantiago'), t('flights.CheaperThanCoruna')],
        },
      ],
    },
  ];

  const flightTips = [
    t('flights.BookFlightsInAdvance'),
    t('flights.UseFlightWebsites'),
    t('flights.LookAtLayovers'),
    t('flights.DirectFlightsToMadridAndBarcelona'),
    t('flights.ConsiderNightsInOtherCities'),
    t('flights.TrainRenfe'),
  ];

  return (
    <RecommendationsBox title={t('flights.Title')} description={t('flights.Description')}>
      <Wrap spacing={4} justify="center" width={'100%'}>
        {flightRecommendations.map((recommendation, index) => (
          <Box
            key={index}
            bg="white"
            p={4}
            rounded="xl"
            width={{ base: '100%', lg: '48%' }}
            boxShadow="lg"
            transition="all 0.3s"
          >
            <Heading size="sm" fontFamily="Poppins" color="black" mb={2}>
              {recommendation.title}
            </Heading>
            <VStack alignItems={'baseline'}>
              {recommendation.options.map((option, subIndex) => (
                <Box key={subIndex}>
                  {option.details.map((detail, detailIndex) => (
                    <Text key={detailIndex} fontSize={'md'}>{detail}</Text>
                  ))}
                </Box>
              ))}
            </VStack>
          </Box>
        ))}
      </Wrap>

      <Divider my={6} />

      <Box
        bg="white"
        p={4}
        mt={{ base: 2, md: 4 }}
        rounded="xl"
        boxShadow="xl"
        border="1px solid #E2E8F0"
        maxW="700px"
        marginX="auto"
        transition="all 0.3s"
        _hover={{
          shadow: "2xl",
        }}
        textAlign={'center'}
      >
        <Heading size="sm" fontFamily="Poppins" color="black" mb={2}>
          {t('flights.Tips')}
        </Heading>
        <UnorderedList listStyleType="disc" pl={4} textAlign={'start'}>
          {flightTips.map((tip, index) => (
            <ListItem key={index} fontSize="md" color="black">
              {tip}
            </ListItem>
          ))}
        </UnorderedList>
      </Box>

    </RecommendationsBox>
  );
};

export default Flights;
