import { Box, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRSVPApi } from "../../hooks/useRSVPApi";
import Auth from "../Auth/Auth";
import RSVPConfirmationDialog from "./RSVPConfirmationDialog";
import RSVPForm from "./RSVPForm";
import { Guest } from "./types";


const RSVPPassword = () => {
    const toast = useToast();
    const { t } = useTranslation();
    const [isRSVPOpen, setRSVPOpen] = useState(false);
    const [rsvpData, setRsvpData] = useState<Guest[] | null>(null);
    const [authenticated, setAuthenticated] = useState(false);
    const { createRSVP } = useRSVPApi();
    const [isLoading, setIsLoading] = useState(false);

    const handleRSVP = (guests: Guest[]) => {
        setRsvpData(guests);
        setRSVPOpen(true);
    };

    const handleConfirmData = async () => {
        if (rsvpData) {
            try {
                setIsLoading(true);
                await createRSVP(rsvpData)
                setRSVPOpen(false);
                setIsLoading(false);
                toast({
                    title: 'RSVP data confirmed',
                    description: t("rsvpForm.confirmation"),
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            } catch (error) {
                toast({
                    title: 'Error',
                    description: 'Failed to confirm RSVP. Contact with Álvaro or Katelyn please.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        }
    }

        const handleAuthenticate = (password: string) => {
            if (password === `${process.env.REACT_APP_RSVP_PASS}`) {
                setAuthenticated(true);
            } else {
                toast({
                    title: 'Authentication Failed',
                    description: 'Invalid password. Please try again.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        };

        return (
            <Box pt={6} width={'100%'}>
                {authenticated ? (
                    <>
                        <RSVPForm onRSVP={handleRSVP} />
                        <RSVPConfirmationDialog isOpen={isRSVPOpen} onConfirm={async () => await handleConfirmData()} onClose={ () => {setRSVPOpen(false)}} rsvpData={rsvpData} isLoading={isLoading} />
                    </>
                ) : (
                    <Auth
                        isOpen={!authenticated}
                        onAuthenticate={handleAuthenticate}
                        title={t('rsvpForm.authenticationTitle')}
                        message={t('rsvpForm.authenticationMessage')}
                    />
                )}
            </Box>
        );
    };

export default RSVPPassword;
