import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface ImageCardProps {
  title: string;
  description: string;
  icon: IconDefinition;
  link: string;
}

const ImageCard: React.FC<ImageCardProps> = ({ title, description, icon, link }) => {
  const handleCardClick = () => {
    window.open(link, '_blank', 'noopener,noreferrer');
  };

  return (
    <Box
      bg="white"
      p={6}
      rounded="xl"
      boxShadow="lg"
      height={'160px'}
      width={{ base: '100%', md: '45%', lg: '30%' }}
      cursor="pointer"
      transition="all 0.3s"
      _hover={{ transform: 'scale(1.02)', boxShadow: '2xl' }}
      onClick={handleCardClick}
    >
      <HStack alignItems="center" justifyItems={'center'} spacing={4} height={'full'}>
        <FontAwesomeIcon icon={icon} size="3x" color="black" />
        <VStack alignItems="flex-center" spacing={1}>
          <Text fontSize="2xl" fontWeight="bold" fontFamily="Poppins">
            {title}
          </Text>
          <Text fontSize="md" color="gray.600" fontFamily="Poppins">
            {description}
          </Text>
        </VStack>
      </HStack>
    </Box>
  );
};

export default ImageCard;
