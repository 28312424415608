import { Box, Center, FormControl, FormLabel, InputGroup, InputRightAddon, NumberInput, NumberInputField, Spinner, Stack, Text, VStack } from '@chakra-ui/react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ExchangeRate = () => {
    const { t } = useTranslation();
    const [exchangeRate, setExchangeRate] = useState<number | null>(null);
    const [loading, setLoading] = useState(true);
    const [usdAmount, setUsdAmount] = useState<number | string>('');

    useEffect(() => {
        const fetchExchangeRate = async () => {
            try {
                const response = await axios.get(
                    'https://open.er-api.com/v6/latest/USD'
                );
                const { rates } = response.data;
                const rate = rates.EUR;
                setExchangeRate(rate);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching exchange rate:', error);
                setLoading(false);
            }
        };

        fetchExchangeRate();
    }, []);

    const handleUsdAmountChange = (valueAsString: string, valueAsNumber: number) => {
        setUsdAmount(valueAsNumber);
    };

    const convertToEur = () => {
        const usdAmountString = String(usdAmount); // Ensure usdAmount is treated as a string
        if (!isNaN(parseFloat(usdAmountString))) {
            const usdValue = parseFloat(usdAmountString);
            const eurValue = usdValue * exchangeRate!;
            return eurValue.toFixed(2);
        } else {
            return t('currency.exchangeRate.InvalidInput');
        }
    };

    return (
        <Box p={4} rounded="lg" boxShadow="md" bg="white" py={8} width={'100%'}>
            <Text fontSize="xl" mb={4}>
                {t('currency.exchangeRate.Title')}
            </Text>
             <Text mb={4} color="gray.600" fontSize="sm">
                {t('currency.exchangeRate.Description')}
            </Text>
            {loading ? (
                <Spinner size="lg" />
            ) : (
                <>
                    {exchangeRate !== null ? (
                        <Stack direction={{ base: 'column', lg: 'row' }} width={'100%'} spacing={4} justify={'space-between'} px={{ base: 2, md: 8 }}>
                            <Center>
                                <Text fontSize="2xl" fontWeight="bold" mb={2}>
                                    {t('currency.exchangeRate.ExchangeRateText', { rate: exchangeRate.toFixed(2) })}
                                </Text>
                            </Center>
                            <VStack width={{ base: '100%', lg: '40%' }}>
                                <FormControl width={'100%'}>
                                    <FormLabel>{t('currency.exchangeRate.EnterAmount')}</FormLabel>
                                    <InputGroup width={'100%'}>
                                        <NumberInput defaultValue={0} onChange={handleUsdAmountChange} width={'100%'}>
                                            <NumberInputField />
                                        </NumberInput>
                                        <InputRightAddon children="USD" />
                                    </InputGroup>
                                </FormControl>
                                {usdAmount && convertToEur() && <Text fontSize="2xl" fontWeight="bold" mt={2}>
                                    {usdAmount && convertToEur()} EUR
                                </Text>}
                            </VStack>
                        </Stack>
                    ) : (
                        <Text fontSize="lg" color="red.500">
                            {t('currency.exchangeRate.FetchError')}
                        </Text>
                    )}
                </>
            )}
        </Box>
    );
};

export default ExchangeRate;