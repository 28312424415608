import { SimpleGrid } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import RecommendationsBox from '../RecommendationsBox';
import Cities from './Cities';
import RentingACar from './RentingACar';

const Activities = () => {
    const { t } = useTranslation();

    return (
        <RecommendationsBox title={t('activities.Title')} description={t('activities.Description')}>
            <SimpleGrid columns={{base: 1, lg: 2}} gap={6}>
                <Cities />
                <RentingACar />
            </SimpleGrid>
        </RecommendationsBox>
    );
};

export default Activities;
