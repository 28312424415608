import { Grid, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import RecommendationsBox from '../RecommendationsBox';
import OrderingInSpanish from './OrderingInSpanish';
import RecommendedBreakfast from './RecommendedPlaces/RecommendedBreakfast';
import RecommendedLunch from './RecommendedPlaces/RecommendedLunch';
import TipsToOrder from './TipsToOrder';
import TypicalDrinks from './TypicalDrinks';
import TypicalFood from './TypicalFood';

const Restaurants = () => {
    const { t } = useTranslation();

    return (<>
        <RecommendationsBox title={t('restaurants.Title')} description={t('restaurants.Description')} display={{base: 'none', lg: 'block'}}>
            <Grid templateColumns='repeat(2, 1fr)' gap={6}>
            <VStack spacing={4} align="center" width={'100%'}>
                <RecommendedBreakfast />
                <TypicalFood />
                <TipsToOrder />
            </VStack>
            <VStack spacing={4} align="center" width={'100%'}>
                <RecommendedLunch />
                <TypicalDrinks />
                <OrderingInSpanish />
                </VStack>
                </Grid>
        </RecommendationsBox>
        <RecommendationsBox title={t('restaurants.Title')} description={t('restaurants.Description')} display={{base: 'block', lg: 'none'}}>
            <VStack spacing={6} align="center" width={'100%'}>
                <RecommendedBreakfast />
                <RecommendedLunch />
                <TypicalFood />
                <TypicalDrinks />
                <TipsToOrder />
                <OrderingInSpanish />
            </VStack>
            </RecommendationsBox>
            </>
    );
};

export default Restaurants;
