import { Button, Link as ChakraLink, Menu, MenuButton, MenuItem, MenuList, useDisclosure } from '@chakra-ui/react';
import { FC } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

interface Option {
  title: string;
  url: string;
}

interface Props {
  title: string;
  url: string;
  options: Option[];
  redirectUrl: string;
}

const Dropdown: FC<Props> = ({ options, title, url, redirectUrl }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const navigate = useNavigate();


  const handleButtonClick = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    void navigate(redirectUrl);
  };

  return (
    <Menu isOpen={isOpen}>
      {() => (
        <>
          <MenuButton
            as={Button}
            mr={2}
            bgColor="white"
            fontSize="lg"
            fontFamily="Poppins"
            textUnderlineOffset="0.2em"
            textDecoration={location?.pathname.includes(url) ? 'underline' : 'none'}
            _hover={{
              textDecoration: 'underline',
            }}
            _active={{
              textDecoration: 'underline',
            }}
            onMouseEnter={onOpen}
            onMouseLeave={onClose}
            onClick={handleButtonClick}
          >
            {title}
          </MenuButton>
          <MenuList
            zIndex={5}
            border={0}
            shadow={0}
            pt={0}
			      textUnderlineOffset={'0.2em'}
            fontSize="lg"
            minWidth="200px"
            transitionDelay={isOpen ? '' : '0.3s'}
            transitionDuration={isOpen ? '' : '0.3s'}
            marginTop="-10px"
            boxShadow={'lg'}
            borderBottomRadius={'xl'}
            onMouseEnter={onOpen}
            onMouseLeave={onClose}
          >
            {options?.map(option => (
              <MenuItem
                key={option.url}
				width={'100%'}
                _hover={{
                  textDecoration: 'underline',
                }}
                _active={{
                  textDecoration: 'underline',
                }}
                backgroundColor="white"
                py={2}
                fontFamily="Poppins"
              >
                <ChakraLink as={Link} to={option.url} fontWeight="semibold" textTransform="capitalize" textAlign={'center'} width={'100%'}>
                  {option.title}
                </ChakraLink>
              </MenuItem>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default Dropdown;
