import { HotelInfoType } from "./types";


const hotelsData: HotelInfoType[] = [
    {
        id: "g18192782-d597034",
        name: "Pazo do Río",
        stars: 4,
        address: "In Oleiros, about 8 km from the center of A Coruña",
        phone: "Not Available",
        website: "https://www.booking.com/hotel/es/hotelmonumentopazodorio.html",
        priceRange: "$$$",
        location: "Oleiros, Spain",
        amenities: [
            "Outdoor Swimming Pool",
            "Restaurant",
            "Jacuzzi",
            "Pond",
            "Chapel",
            "Outdoor Spaces",
        ],
        distanceFromVenue: "",
        rating: 4.4,
        reviews: 482,
    },
    {
        id: "g187507-d228530",
        name: "Meliá Maria Pita",
        stars: 4,
        address: "In the heart of A Coruña, next to Maria Pita Square and the Riazor Beach",
        phone: "Not Available",
        website: "https://www.booking.com/hotel/es/meliamariapita.html",
        priceRange: "$$$",
        location: "A Coruña, Spain",
        amenities: ["Restaurant", "Bar", "Fitness Center", "Spa", "Outdoor Swimming Pool"],
        distanceFromVenue: "",
        rating: 4.4,
        reviews: 2546,
    },
    {
        id: "g187507-d228505",
        name: "Hotel Riazor",
        stars: 4,
        address: "On the Riazor Beach, with stunning views of the Atlantic Ocean",
        phone: "Not Available",
        website: "https://www.booking.com/hotel/es/riazor.de.html",
        priceRange: "$$",
        location: "A Coruña, Spain",
        amenities: ["Restaurant", "Bar", "Café", "Fitness Center", "Outdoor Swimming Pool"],
        distanceFromVenue: "",
        rating: 4.3,
        reviews: 2050,
    },
    {
        id: "g187507-d231608",
        name: "Hotel NH Finisterre A Coruña",
        stars: 5,
        address: "In the Riazor area, close to the beach and the city center",
        phone: "Not Available",
        website: "https://www.booking.com/hotel/es/nh-collection-a-coruna-finisterre.html",
        priceRange: "$$$",
        location: "A Coruña, Spain",
        amenities: [
            "Restaurant",
            "Bar",
            "Outdoor Swimming Pool",
            "Fitness Center",
            "Spa",
        ],
        distanceFromVenue: "",
        rating: 4.5,
        reviews: 2224,
    },
    {
        id: "g187507-d1448091",
        name: "DoubleTree by Hilton A Coruña",
        stars: 4,
        address: "In the city center, close to the main shopping and dining areas",
        phone: "Not Available",
        website: "https://www.hilton.com/en/hotels/lcgacdi-doubletree-a-coruna/",
        priceRange: "$$$",
        location: "A Coruña, Spain",
        amenities: ["Restaurant", "Bar", "Café", "Fitness Center", "Spa", "Indoor Pool"],
        distanceFromVenue: "",
        rating: 4.7,
        reviews: 415,
    },
    {
        id: "g187507-d23830042",
        name: "Attica21 Coruña",
        stars: 4,
        address: "In the city center, close to the main attractions",
        phone: "Not Available",
        website: "https://www.travelweekly.com/Hotels/La-Coruna-Spain/Attica-21-La-Coruna-p51835952",
        priceRange: "$$",
        location: "A Coruña, Spain",
        amenities: ["Restaurant", "Bar", "Café", "Fitness Center", "Spa"],
        distanceFromVenue: "",
        rating: 4.6,
        reviews: 1800,
    },
    {
        id: "g187507-d231608",
        name: "Hotel Exe Coruña",
        stars: 4,
        address: "In the city center, close to the main shopping and dining areas",
        phone: "Not Available",
        website: "https://hotelexecorunaacoruna.com-hotel.com/es/",
        priceRange: "$$",
        location: "A Coruña, Spain",
        amenities: ["Restaurant", "Bar", "Café", "Fitness Center"],
        distanceFromVenue: "",
        rating: 4.3,
        reviews: 1350,
    },
    {
        id: "g187507-d1389214",
        name: "Hotel Alda Orzán",
        stars: 2,
        address: "On the Orzán Beach, with stunning views of the Atlantic Ocean",
        phone: "Not Available",
        website: "https://www.booking.com/hotel/es/sol-a-coruna1.html",
        priceRange: "$",
        location: "A Coruña, Spain",
        amenities: ["Restaurant", "Bar", "Café", "Fitness Center"],
        distanceFromVenue: "",
        rating: 4.2,
        reviews: 1200,
    },
    {
        id: "g187507-d1389214",
        name: "Hotel Brisa",
        stars: 1,
        address: "Close to the Riazor Beach and the city center",
        phone: "Not Available",
        website: "https://www.booking.com/hotel/es/brisa-a-coruna.html",
        priceRange: "$",
        location: "A Coruña, Spain",
        amenities: ["Restaurant", "Bar", "Café"],
        distanceFromVenue: "",
        rating: 4.4,
        reviews: 1000,
    },
    {
        id: "g187507-d25223349",
        name: "Hostal Costa Coruña",
        stars: 1,
        address: "In the city center, close to the main attractions",
        phone: "Not Available",
        website: "https://www.booking.com/hotel/es/hostal-costa-coruna-a-coruna.es.html",
        priceRange: "$",
        location: "A Coruña, Spain",
        amenities: ["Bar", "Café"],
        distanceFromVenue: "",
        rating: 4.3,
        reviews: 950,
    },
    {
        id: "g187507-d1383647",
        name: "Hotel Francisco Javier",
        stars: 1,
        address: "In the city center, close to the main attractions",
        phone: "Not Available",
        website: "https://www.booking.com/hotel/es/francisco-javier.es.html",
        priceRange: "$",
        location: "A Coruña, Spain",
        amenities: ["Bar", "Café"],
        distanceFromVenue: "",
        rating: 4.2,
        reviews: 900,
    }
    
    // Add more hotels if needed
];

export default hotelsData;
