import { Box, Heading, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const Cities = () => {
    const { t } = useTranslation();

    return (
        <Box bg="white" p={4} py={8} rounded="xl" boxShadow="lg" width={'100%'}>
            <Heading size="md" mb={4} width={'100%'} fontFamily={'Poppins'}>
                {t('activities.cities.Title')}
            </Heading>
            <Text>{t('activities.cities.Intro')}</Text>
            <VStack alignItems={'baseline'} spacing={{ base: 6, lg: 8 }} mt={{ base: 6, lg: 8 }}>
                <VStack alignItems={'baseline'}>
                    <Text fontWeight="semibold">{t('activities.cities.SantiagoDeCompostela.Title')}</Text>
                    <Text>{t('activities.cities.SantiagoDeCompostela.Description')}</Text>
                </VStack>
                <VStack alignItems={'baseline'}>
                    <Text fontWeight="semibold">{t('activities.cities.Vigo.Title')}</Text>
                    <Text>{t('activities.cities.Vigo.Description')}</Text>
                </VStack>
                <VStack alignItems={'baseline'}>
                    <Text fontWeight="semibold">{t('activities.cities.Vigo.IslasCies')} & {t('activities.cities.Vigo.IslasOns')}</Text>
                    <Text>{t('activities.cities.Vigo.IslasCiesDescription')}</Text>
                </VStack>
                <VStack alignItems={'baseline'}>
                    <Text fontWeight="semibold">{t('activities.cities.Fisterra.Title')}</Text>
                    <Text>{t('activities.cities.Fisterra.Description')}</Text>
                </VStack>
                <VStack alignItems={'baseline'}>
                    <Text fontWeight="semibold">{t('activities.cities.Porto.Title')}</Text>
                    <Text>{t('activities.cities.Porto.DescriptionFromCoruna')}</Text>
                    <Text>{t('activities.cities.Porto.DescriptionFromVigo')}</Text>
                </VStack>
            </VStack>
        </Box>
    );
};

export default Cities;
