/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Input, Stack, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useRSVPApi } from '../../hooks/useRSVPApi';
import LoadingSpinner from '../Layout/LoadingSpinner';
import ErrorText from './ErrorText';
import GuestCard from './GuestCard';
import NoRSVPText from './NoRSVPText';

const Dashboard = () => {
  const { rsvpData, loading, error, fetchRSVPs } = useRSVPApi();
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchRSVPs();
  }, []);

  // Split guests into two arrays: attending and not attending
  const attendingGuests = rsvpData.filter((guest) => guest.attendance === 'Yes');
  const notAttendingGuests = rsvpData.filter((guest) => guest.attendance === 'No');

  // Filter guests based on the search term
  const filteredAttendingGuests = attendingGuests.filter((guest) =>
    guest.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredNotAttendingGuests = notAttendingGuests.filter((guest) =>
    guest.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box>
      <Text fontSize="2xl" fontWeight="bold" mb={4}>
        RSVP Dashboard
      </Text>
      <Input
        placeholder="Search by name"
        bgColor={'white'}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      {loading && <LoadingSpinner />}
      {error && <ErrorText error={error} />}
      <Stack justify="space-between" direction={{ base: 'column', lg: 'row' }} mt={4}>
        <VStack align="start" spacing={4} width="100%">
          {filteredAttendingGuests.map((guest, index) => (
            <GuestCard key={index} guest={guest} />
          ))}
        </VStack>
        <VStack align="start" spacing={4} width="100%">
          {filteredNotAttendingGuests.map((guest, index) => (
            <GuestCard key={index} guest={guest} />
          ))}
        </VStack>
      </Stack>
      {rsvpData.length === 0 && !loading && <NoRSVPText />}
    </Box>
  );
};

export default Dashboard;


