import React, { useState } from 'react';
import { Box, useToast } from '@chakra-ui/react';
import Dashboard from './Dashboard/Dashboard';
import AuthModal from './Auth/Auth';
import { useTranslation } from 'react-i18next';


const DashboardPage: React.FC = () => {
    const [authenticated, setAuthenticated] = useState(false);
    const { t } = useTranslation();
    const toast = useToast();

    const handleAuthenticate = (password: string) => {
        // Replace 'yourSecretPassword' with your actual password
        if (password === `${process.env.REACT_APP_DASHBOARD_PASS}`) {
            setAuthenticated(true);
        } else {
            toast({
                title: 'Authentication Failed',
                description: 'Invalid password. Please try again.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Box p={6}>
            {!authenticated ? <AuthModal
                    isOpen={!authenticated}
                    onAuthenticate={handleAuthenticate}
                    title={t('dashboard.authenticationTitle')}
                    message={t('dashboard.authenticationMessage')}
                /> : null}
            {authenticated ? <Dashboard /> : null}
        </Box>
    );
};

export default DashboardPage;
