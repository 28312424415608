import { Box, Center, Heading } from '@chakra-ui/react';

const Footer = () => {
  return (
    <Box as="footer" py={20} bgColor={'#e5e5e5'}>
      <Center>
        <Heading as="h3" size="xl" fontWeight={'normal'}>Katelyn & Álvaro</Heading>
      </Center>
    </Box>
  );
};

export default Footer;
