import { Box } from "@chakra-ui/react";
import RSVPPassword from "./RSVPPassword";
import Gifts from "./Gifts";

const RSVPUnitedStates = () => {
    return (
        <Box width={'100%'}>
            <RSVPPassword />
            <Gifts />
        </Box>
    );
};

export default RSVPUnitedStates;
