import { Stack } from "@chakra-ui/react";
import RSVPCountrySelector from "./RSVP/RSVPCountrySelector";

const RSVPPage = () => {
   return(
    <Stack direction={{base: 'column', lg: 'row'}}>
        <RSVPCountrySelector />
        
    </Stack>
   )
}

export default RSVPPage;
