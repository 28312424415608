import { Box, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const CardTypes = () => {
    const cardImageSize = '46px'; // Adjust the size as needed
    const { t } = useTranslation();

    return (
        <Box p={4} rounded="lg" boxShadow="md" bg="white" py={8} minW={'300px'} height={'-webkit-fit-content'}>
            <VStack spacing={6}>
                <VStack spacing={1}>
                    <Text mb={2} fontSize="md" color="black">
                        {t('currency.cardTypes.Accepted')}
                    </Text>
                    <HStack spacing={4}>
                        <Box maxW={cardImageSize} maxH={cardImageSize}>
                            <Image src="/img/cards/visa.png" alt="VISA Logo" w="100%" h="100%" />
                        </Box>
                        <Box maxW={cardImageSize} maxH={cardImageSize}>
                            <Image src="/img/cards/mastercard.png" alt="Mastercard Logo" w="100%" h="100%" />
                        </Box>
                        <Box maxW={cardImageSize} maxH={cardImageSize}>
                            <Image src="/img/cards/applepay.png" alt="Apple Pay Logo" w="100%" h="100%" />
                        </Box>
                        <Box maxW={cardImageSize} maxH={cardImageSize}>
                            <Image src="/img/cards/gpay.png" alt="Google Pay Logo" w="100%" h="100%" />
                        </Box>
                    </HStack>
                </VStack>
                <VStack spacing={1}>
                    <Text mb={2} fontSize="md" color="black">
                    {t('currency.cardTypes.NotGenerallyAccepted')}
                    </Text>
                    <HStack spacing={4}>
                        <Box maxW={cardImageSize} maxH={cardImageSize}>
                            <Image src="/img/cards/amex.png" alt="AMEX Logo" w="100%" h="100%" />
                        </Box>
                        <Box maxW={cardImageSize} maxH={cardImageSize}>
                            <Image src="/img/cards/discover.png" alt="Discover Logo" w="100%" h="100%" />
                        </Box>
                    </HStack>
                </VStack>
            </VStack>
        </Box>
    );
};

export default CardTypes;
