import { Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import RestaurantsSection from './RestaurantsSection/RestaurantsSection';

const OrderingInSpanish = () => {
    const { t, i18n } = useTranslation();

    const orderPhrases = [
        { phrase: 'Please', translation: '"porfa" PORE-fah' },
        { phrase: 'Could you get me...?', translation: '"Me pones _____?" meh-PONE-ehs' },
        { phrase: 'Glass of __(type of wine)___', translation: '"Una copa de ______." OO-na COPE-a dey' },
        { phrase: 'A small beer (usually Estrella)', translation: '"Una caña" OO-na CAHN-ya' },
        { phrase: 'Can I pay?', translation: '"Me cobras?" Meh COBE-ras?' },
        { phrase: 'Latte', translation: '"café con leche" cah-FAY CONE LEY-chey' },
        { phrase: 'Espresso', translation: '"café solo" cah-FAY SOE-low' },
    ];

    return (
            <RestaurantsSection title={t('restaurants.orderTips.OrderingInSpanish')}>
                {i18n.language === 'en' && (
                    <VStack spacing={4} justify="space-between" textAlign="center">
                        <VStack>
                            {orderPhrases.map((item, index) => (
                                <VStack key={index} pt={2}>
                                    <Text fontWeight="semibold" fontSize={'lg'}>{item.phrase}</Text>
                                    <Text>{item.translation}</Text>
                                </VStack>
                            ))}
                        </VStack>
                    </VStack>
                )}
                {i18n.language === 'es' && (
                    <Text textAlign="center" fontSize="md">
                        Si estás leyendo esto en español, no necesitas esta parte ;).
                    </Text>
                )}
            </RestaurantsSection>
    );
};

export default OrderingInSpanish;
