import { Box, Button, Center, HStack, Text, VStack, Image } from "@chakra-ui/react";
import { useState } from "react";
import RSVPPassword from "./RSVPPassword";
import RSVPUnitedStates from "./RSVPUnitedStates";
import { useTranslation } from "react-i18next";

// Define constants for countries
const COUNTRIES = {
    SPAIN: "Spain",
    UNITED_STATES: "UnitedStates",
    // Add more countries as needed
};

const RSVPCountrySelector = () => {
    const [country, setCountry] = useState<string | null>(null);
    const { t } = useTranslation();

    if (country === COUNTRIES.SPAIN) {
        return <RSVPPassword />;
    }

    if (country === COUNTRIES.UNITED_STATES) {
        return <RSVPUnitedStates />;
    }

    return (
        <Center minH={'80vh'} width={'100%'}>
            <Box
                bg={"white"}
                p={4}
                rounded="3xl"
                boxShadow="lg"
                borderWidth="1px"
                borderColor={'gray.200'}
                width={{ base: "100%", lg: '48%' }}
                alignItems={'center'}
                textAlign={'center'}
            >
                <VStack spacing={8}>
                    <Text fontSize={'xl'} fontWeight={'bold'} fontFamily={'Poppins'}>{t('rsvpForm.SelectCountry')}</Text>
                    <HStack spacing={10}>
                        <Button onClick={() => setCountry(COUNTRIES.SPAIN)} size={{base: "xl", md: '2xl'}} backgroundColor={'transparent'} _hover={{bgColor:'transparent', transform: "scale(1.1)"}}>
                            <Image src="img/spain-flag.png" boxSize={{base: "70px", md: '100px'}} />
                        </Button>
                        <Button onClick={() => setCountry(COUNTRIES.UNITED_STATES)} size={{base: "xl", md: '2xl'}} backgroundColor={'transparent'} _hover={{bgColor:'transparent', transform: "scale(1.1)"}}>
                            <Image src="img/usa-flag.png" boxSize={{base: "70px", md: '100px'}} />
                        </Button>
                    </HStack>
                </VStack>
            </Box>
        </Center>
    );
};

export default RSVPCountrySelector;
