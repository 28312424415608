// NoRSVPText.js
import { Text } from '@chakra-ui/react';

const NoRSVPText = () => {
  return (
    <Text fontSize="xl" mt={4}>
      No RSVPs available. Be the first to RSVP!
    </Text>
  );
};

export default NoRSVPText;
