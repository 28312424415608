import { Box, Heading } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
interface RestaurantsSectionProps extends PropsWithChildren {
    title?: string
}

const RestaurantsSection: React.FC<RestaurantsSectionProps> = ({title, children }) => {

    return (
        <Box bg="white" p={4} rounded="xl" boxShadow="lg" height={'-webkit-fit-content'} width={"100%"}>
            <Heading size={{base: 'sm', md:"md"}} mb={4} width={'100%'} textAlign={'center'} fontFamily={'Poppins'}>
                {title}
            </Heading>
            {children}
        </Box>
    );
};

export default RestaurantsSection;
