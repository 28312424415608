import { DeleteIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    HStack,
    IconButton,
    Input,
    Radio,
    RadioGroup,
    Textarea,
    VStack,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Guest } from './types';

interface RSVPFormProps {
    onRSVP: (guests: Guest[]) => void;
}

const RSVPForm: React.FC<RSVPFormProps> = ({ onRSVP }: RSVPFormProps) => {
    const { t } = useTranslation();
    const toast = useToast();
    const { onOpen } = useDisclosure();
    const [guests, setGuests] = useState<Guest[]>([{ id: 0, name: '', attendance: '', message: '' }]);

    const handleRSVP = () => {
        if (validateGuests(guests)) {
            onRSVP(guests);
            onOpen();
        } else {
            toast({
                title: 'Error',
                description: t('rsvpForm.fillRequiredFields'),
                status: 'error',
                duration: 9000,
                isClosable: true
            });
        }
    };

    const addGuest = () => {
        const newId = guests.length;
        setGuests([...guests, { id: newId, name: '', attendance: '', message: '' }]);
    };

    const removeGuest = (id: number) => {
        if (guests.length > 1) {
            const updatedGuests = guests.filter((guest) => guest.id !== id);
            setGuests(updatedGuests);
        }
    };

    const updateGuestField = (id: number, field: string, value: any) => {
        const updatedGuests = guests.map((guest) => {
            if (guest.id === id) {
                return { ...guest, [field]: value };
            }
            return guest;
        });
        setGuests(updatedGuests);
    };

    const validateGuests = (guests: Guest[]) => {
        return guests.every((guest) => guest.name && guest.attendance);
    };

    return (
        <Box width="100%" >
            <form style={{ width: '100%' }}>
                <VStack width="100%" spacing={4}>

                    {guests.map((guest, index) => (
                        <VStack
                            key={guest.id}
                            borderWidth="1px"
                            borderRadius="lg"
                            bgColor="white"
                            p={6}
                            width="100%"
                            spacing={5}
                            position="relative"
                            boxShadow={'lg'}
                        >
                            {index > 0 && (
                                <IconButton
                                    aria-label="Remove Guest"
                                    icon={<DeleteIcon />}
                                    size="sm"
                                    color="red"
                                    position="absolute"
                                    zIndex={1}
                                    top="5px"
                                    right="5px"
                                    onClick={() => removeGuest(guest.id)}
                                />
                            )}
                            <FormControl isRequired>
                            <FormLabel top='-24px' left={0} zIndex={2} position='absolute' backgroundColor='white' pointerEvents='none' mx='3' px='1' my={3} fontSize='sm'>{t('rsvpForm.yourName')}</FormLabel>
                                <Input
                                    type="text"
                                    fontSize={'sm'}
                                    value={guest.name}
                                    onChange={(e) => updateGuestField(guest.id, 'name', e.target.value)}
                                />
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel fontSize='sm'>{t('rsvpForm.attendance')}</FormLabel>
                                <RadioGroup
                                    value={guest.attendance}
                                    onChange={(value) => updateGuestField(guest.id, 'attendance', value)}
                                >
                                    <HStack spacing="24px">
                                        <Radio value="Yes">{t('rsvpForm.yes')}</Radio>
                                        <Radio value="No">{t('rsvpForm.no')}</Radio>
                                    </HStack>
                                </RadioGroup>
                            </FormControl>
                            <FormControl>
                            <FormLabel top='-24px' left={0} zIndex={2} position='absolute' backgroundColor='white' pointerEvents='none' mx='3' px='1' my={3} fontSize='sm'>{t('rsvpForm.messageOptional')}</FormLabel>
                                <Textarea
                                    value={guest.message}
                                    fontSize={'sm'}
                                    onChange={(e) => updateGuestField(guest.id, 'message', e.target.value)}
                                />
                            </FormControl>
                        </VStack>
                    ))}
                    <HStack>
                        <Button colorScheme="teal" onClick={addGuest}>
                            {t('rsvpForm.addAnotherGuest')}
                        </Button>
                        <Button colorScheme="teal" onClick={handleRSVP}>
                            {t('rsvpForm.rsvpButton')}
                        </Button>
                    </HStack>
                </VStack>
            </form>
        </Box>
    );
};

export default RSVPForm;
