import { Box } from '@chakra-ui/react';
import { Variants, motion } from 'framer-motion';
import React from 'react';

interface StoryItemProps {
    children: React.ReactNode;
}

const cardVariants: Variants = {
    offscreen: {
        y: 300
    },
    onscreen: {
        y: 0,
        transition: {
            type: "spring",
            bounce: 0.4,
            duration: 0.8
        }
    }
};


const StoryItem: React.FC<StoryItemProps> = ({ children, }) => (
    <Box p={{ base: 4, md: 6 }} mb={4} borderRadius="md">
        <motion.div
            className="card-container"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}
        >
            <motion.div
                className="card"
                variants={cardVariants}
            >
                {children}
            </motion.div>
        </motion.div>
    </Box>
);

export default StoryItem;
