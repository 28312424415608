import { Box, Button, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSelector = () => {
  const { t, i18n } = useTranslation();

  const languageFlags: Record<string, string> = {
    en: '🇺🇸', // Emoji flag for English
    es: '🇪🇸', // Emoji flag for Spanish
  };

  const [showLanguagePopover, setShowLanguagePopover] = useState(false);

  const toggleLanguagePopover = () => {
    setShowLanguagePopover(!showLanguagePopover);
  };

  const toggleLanguage = () => {
    i18n.changeLanguage(i18n.language === 'en' ? 'es' : 'en');
  };

  return (
    <Popover isOpen={showLanguagePopover} onClose={() => setShowLanguagePopover(false)}>
      <PopoverTrigger>
        <Button
          borderRadius="50%"
          width="3rem"
          height="3rem"
          fontSize="1.5rem"
          lineHeight="3rem"
          bgColor="transparent"
          marginRight="1rem"
          _hover={{ bgColor: 'transparent' }}
          onClick={toggleLanguagePopover}
        >
          {languageFlags[i18n.language]}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>{t('languageSelector.SelectLanguage')}</PopoverHeader>
        <PopoverBody>
          <Box display="flex" flexDirection="column">
            <Button onClick={() =>toggleLanguage()}>{t('languageSelector.SelectLanguageButton')}</Button>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default LanguageSelector;
