import { Box, Stack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import RecommendationsBox from '../RecommendationsBox';
import CardTypes from './CardTypes';
import ExchangeRate from './ExchangeRate';

const Currency = () => {
    const { t } = useTranslation();

    return (
        <RecommendationsBox title={t('currency.Title')} description={t('currency.Description')} >
            <Box bg="white" p={4} rounded="xl" boxShadow="lg" width={'100%'} my={4}>
                <Text>{t('currency.CardInfo.CurrencyUsage')}</Text>
                <Text fontWeight="semibold" mt={4}>
                    {t('currency.CardInfo.CanIPayByCardEverywhere.Title')}
                </Text>
                <Text>{t('currency.CardInfo.CanIPayByCardEverywhere.Description')}</Text>
                <Text fontWeight="semibold" mt={4}>
                    {t('currency.CardInfo.BestWayToSpend.Title')}
                </Text>
                <Text>{t('currency.CardInfo.BestWayToSpend.Description')}</Text>
                <Text fontWeight="semibold" mt={4}>
                    {t('currency.CardInfo.TransferOrReceiveMoney.Title')}
                </Text>
                <Text>{t('currency.CardInfo.TransferOrReceiveMoney.Description')}</Text>
            </Box>
            <Stack direction={{ base: 'column', md: 'row' }} width={'100%'}>
                <ExchangeRate />
                <CardTypes />
            </Stack>
        </RecommendationsBox>
    );
};

export default Currency;
