import { Box, Heading, Text, Wrap } from '@chakra-ui/react';
import { faEuroSign, faHotel, faPlaneDeparture, faUmbrellaBeach, faUtensils } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import RecommendationCard from './Recommendations/RecommendationCard';


const RecommendationsPage = () => {
  const { t } = useTranslation();

  return (
    <Box py={{ base: 4, md: 6 }} width={"100%"} justifyContent={'center'} textAlign={'center'}>
      <Heading size="lg" mb={4} width={'100%'} textAlign={'center'} fontFamily={'Poppins'}>
        {t('recommendations.Title')}
      </Heading>
      <Text fontSize="md" mb={4} width={'100%'} textAlign={'center'} fontFamily={'Poppins'}>
        {t('recommendations.Description')}
      </Text>

      <Wrap px={0} width={"100%"} justify={'center'} spacing={6} minHeight={"90vh"} verticalAlign={'center'} textAlign={'left'} py={6}>
        <RecommendationCard
          title={t('recommendations.Flights')}
          icon={<FontAwesomeIcon icon={faPlaneDeparture} width={'30%'} size='3x' />}
          description={t('recommendations.FlightsDescription')}
          linkTo='/recommendations/flights'
        />
        <RecommendationCard
          title={t('recommendations.Hotels')}
          icon={<FontAwesomeIcon icon={faHotel} width={'30%'} size='3x' />}
          description={t('recommendations.HotelsDescription')}
          linkTo='/recommendations/hotels'
        />
        <RecommendationCard
          title={t('recommendations.Restaurants')}
          icon={<FontAwesomeIcon icon={faUtensils} width={'30%'} size='3x' />}
          description={t('recommendations.RestaurantsDescription')}
          linkTo='/recommendations/restaurants'
        />
        <RecommendationCard
          title={t('recommendations.Activities')}
          icon={<FontAwesomeIcon icon={faUmbrellaBeach} width={'30%'} size='3x' />}
          description={t('recommendations.ActivitiesDescription')}
          linkTo='/recommendations/activities'
        />
          <RecommendationCard
          title={t('recommendations.Currency')}
          icon={<FontAwesomeIcon icon={faEuroSign} width={'30%'} size='3x' />}
          description={t('recommendations.CurrencyDescription')}
          linkTo='/recommendations/currency'
        />
        {/* Add more links for other recommendation categories */}
      </Wrap>
    </Box>
  );
};

export default RecommendationsPage;
