import { Box, Button, Center, Text, VStack } from "@chakra-ui/react";
import { faGifts } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

const Gifts = () => {
    const {t} = useTranslation();
    const handleGiftClick = () => {
        window.open('https://www.honeyfund.com/site/matt-perez-06-08-2024-1?no_gdpr=1', '_blank');
    }

    return (
        <Box
            width={'100%'}
            borderWidth="1px"
            borderRadius={'3xl'}
            bgColor="white"
            p={6}
            position="relative"
            boxShadow={'xl'}
            mb={10}>
            <Center width='100%'>
                <VStack spacing={4} width='100%' textAlign={'center'}>
                    <FontAwesomeIcon icon={faGifts} size="2x" />
                    <Text fontSize={{base: 'sm', lg: 'md'}} width='100%'>
                        {t('gifts.Text')}
                    </Text>
                    <Button bgColor={'pink.100'} _hover={{bgColor: 'pink.200'}} onClick={handleGiftClick}>{t('gifts.Button')}</Button>
                </VStack>
            </Center>
        </Box>
    );
};
export default Gifts;