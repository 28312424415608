import { SimpleGrid } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import ContactCard from './ContactCard';

const ContactInfo = () => {
    const { t } = useTranslation();

    return (
        <SimpleGrid py={{ base: 2, md: 4 }} px={0} width={"100%"}  spacing={4} columns={{base: 1, md: 2}}>
            <ContactCard title={t('contact.contactInfo.Title')} emailAddress='alvaro33perez@gmail.com' phone='+34664229846' />
            <ContactCard title={t('contact.contactInfo.Title2')} emailAddress='kmatt.9922@gmail.com' phone='+1(262)510-1427' />
        </SimpleGrid>
    );
};

export default ContactInfo;
