import React from "react";
import { SimpleGrid } from "@chakra-ui/react";
import HotelInfo from "./HotelInfo";
import { HotelInfoType } from "./types";

interface HotelsInfoProps {
    hotels: HotelInfoType[];
}

const HotelsInfo: React.FC<HotelsInfoProps> = ({ hotels }) => {
    return (
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
            {hotels.map((hotel, index) => (
                <HotelInfo
                    key={index}
                    hotel={hotel}
                />
            ))}
        </SimpleGrid>
    );
};

export default HotelsInfo;
