import { Box, HStack, Heading, Link, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { FaEnvelope, FaPhone } from 'react-icons/fa';

// ContactCard component to display contact information
interface ContactCardProps {
  title: string;
  emailAddress: string;
  phone: string;
}

const ContactCard: React.FC<ContactCardProps> = ({ title, emailAddress, phone }) => {

  return (
    <Box
      bg={"white"}
      p={4}
      rounded="lg"
      boxShadow="lg"
      borderWidth="1px"
      borderColor={'gray.200'}
      width={"100%"}
    >
      <VStack spacing={4} p={6}>
        <Heading fontSize={{base:'2xl', md: "3xl"}} fontWeight="bold" color="black" fontFamily={'Poppins'}>
          {title}
        </Heading>
        <HStack spacing={4}>
          <FaEnvelope size={'24'} />
          <Text fontSize={'lg'}>
            <Link href={`mailto:${emailAddress}`} color="teal.500">
              {emailAddress}
            </Link>
          </Text>
        </HStack>
        <HStack spacing={4}>
          <FaPhone size={'24'} />
          <Text fontSize={'lg'}>
            <Link href={`tel:${phone}`} color="teal.500">
              {phone}
            </Link>
          </Text>
        </HStack>
      </VStack>
    </Box>
  );
};

export default ContactCard;
