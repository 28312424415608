import { VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import RestaurantsSection from './RestaurantsSection/RestaurantsSection';
import TypicalDrinksSection from './TypicalDrinks/TypicalDrinksSection';

const TypicalDrinks = () => {
    const { t } = useTranslation();

    const typicalDrinkItems = {
        redWines: [
            { name: 'Mencia' }, { name: 'Ribeira', }],
        whiteWines: [{ name: 'Albariño' }, { name: 'Godello' }],
        beer: [
            { name: 'Estrella', description: 'Lager' },
            { name: '1906 "La Milnueve"', description: 'Amber lager' },
            { name: 'Clara de Limon', description: 'Lemon Beer' },
        ],
        other: [
            { name: 'Tinto de Verano', description: t('restaurants.typicalDrinks.TintoDeVeranoDesc') },
            { name: 'Sangria', description: t('restaurants.typicalDrinks.SangriaDesc') },
            { name: 'Licor Café', description: t('restaurants.typicalDrinks.LicorCafeDesc') },
            { name: 'Licor Orujo', description: t('restaurants.typicalDrinks.LicorOrujoDesc') },
            { name: 'Licor de Hierbas', description: t('restaurants.typicalDrinks.LicorHierbasDesc') },
            { name: 'Vermú Mezclado', description: t('restaurants.typicalDrinks.VermuMezcladoDesc') },
        ],
    };

    return (
        <RestaurantsSection title={t('restaurants.typicalDrinks.Drinks')}>
            <VStack spacing={4}>
                <TypicalDrinksSection items={typicalDrinkItems.redWines} title={t('restaurants.typicalDrinks.RedWines')} />
                <TypicalDrinksSection items={typicalDrinkItems.whiteWines} title={t('restaurants.typicalDrinks.WhiteWines')} />
                <TypicalDrinksSection items={typicalDrinkItems.beer} title={t('restaurants.typicalDrinks.Beer')} />
                <TypicalDrinksSection items={typicalDrinkItems.other} title={t('restaurants.typicalDrinks.Other')} />
            </VStack>
        </RestaurantsSection>
    );
};

export default TypicalDrinks;
