import { Box, HStack, Text } from "@chakra-ui/react";
import { FC } from "react";

interface PriceBoxProps {
  webpageURL: string;
  price: number;
  currency: string;
}

const PriceBox: FC<PriceBoxProps> = ({ webpageURL, price, currency }) => {
  
  return (
    <Box width={'100%'} p={2} bgColor={'gray.100'} alignContent={'center'} borderRadius={'lg'}>
        <HStack  width={'100%'} justify={'space-between'}>
            <Text>
                {webpageURL}
            </Text>
            <Text fontWeight={'bold'} fontSize={'lg'}>
                {price} {currency}
            </Text>
        </HStack>
    </Box>
  );
};

export default PriceBox;
