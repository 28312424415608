/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, Text, HStack, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const Countdown = ({ targetDate }: { targetDate: Date }) => {
    const { t } = useTranslation();
    const calculateTimeLeft = () => {
        const difference = targetDate.getTime() - new Date().getTime();
        if (difference > 0) {
            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((difference / (1000 * 60)) % 60);
            const seconds = Math.floor((difference / 1000) % 60);
            return { days, hours, minutes, seconds };
        }
        return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    }, [timeLeft]);

    return (
        <HStack spacing={6} color="white">
            <CountdownBox label={t('homepage.countdown.Days')} value={timeLeft.days} />
            <CountdownBox label={t('homepage.countdown.Hours')} value={timeLeft.hours} />
            <CountdownBox label={t('homepage.countdown.Minutes')} value={timeLeft.minutes} />
            <CountdownBox label={t('homepage.countdown.Seconds')} value={timeLeft.seconds} />
        </HStack>
    );
};

export default Countdown;

const CountdownBox = ({ label, value }: { label: string; value: number }) => {
    return (
        <VStack align="center" w={{ base: "65px", md: "80px" }}>
            <Box bg="white" borderRadius="md" p={2} w="60px" textAlign="center">
                <Text noOfLines={1} fontSize="2xl" color={'black'}>{value < 10 ? `0${value}` : value}</Text>
            </Box>
            <Text fontSize="2xl" mt={2} fontFamily={'Alex Brush'}>
                {label}
            </Text>
        </VStack>
    );
};
