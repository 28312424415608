import React, { useState } from 'react';
import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerOverlay, Flex, IconButton, Link as ChakraLink, VStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HamburgerIcon } from '@chakra-ui/icons';
import LanguageSelector from '../Home/LanguageSelector';
import MobileDropdown from './Header/Dropdown/MobileDropdown';

const MobileMenu: React.FC = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const buttonStyle = {
    color: 'black',
    textDecoration: 'none',
    background: 'transparent',
    border: 'none',
    padding: '0',
    margin: '0',
    cursor: 'pointer',
  };

  const closeMenu = () => {
    toggleMenu();
  };

  return (
    <Flex align="center">
      <LanguageSelector />
      <IconButton
        aria-label="Mobile Menu"
        icon={<HamburgerIcon />}
        size={'lg'}
        _hover={{bgColor: 'transparent'}}
        onClick={toggleMenu}
        bgColor="transparent"
        color="black"
      />
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={toggleMenu}
      >
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerBody mt={10}>
              <VStack spacing={2}>
                <ChakraLink as={Link} to="/home" style={buttonStyle} onClick={closeMenu}>
                  <Button
                    w="100%"
                    bgColor="transparent"
                    color="black"
                    _hover={{ textDecoration: 'underline' }}
                    _active={{ textDecoration: 'underline' }}
                  >
                    {t('header.Home')}
                  </Button>
                </ChakraLink>
                <ChakraLink as={Link} to="/info" style={buttonStyle} onClick={closeMenu}>
                  <Button
                    w="100%"
                    bgColor="transparent"
                    color="black"
                    _hover={{ textDecoration: 'underline' }}
                    _active={{ textDecoration: 'underline' }}
                  >
                    {t('header.Info')}
                  </Button>
                </ChakraLink>
                <ChakraLink as={Link} to="/contact" style={buttonStyle} onClick={closeMenu}>
                  <Button
                    w="100%"
                    bgColor="transparent"
                    color="black"
                    _hover={{ textDecoration: 'underline' }}
                    _active={{ textDecoration: 'underline' }}
                  >
                    {t('header.Contact')}
                  </Button>
                </ChakraLink>
                <ChakraLink as={Link} to="/images" style={buttonStyle} onClick={closeMenu}>
                  <Button
                    w="100%"
                    bgColor="transparent"
                    color="black"
                    _hover={{ textDecoration: 'underline' }}
                    _active={{ textDecoration: 'underline' }}
                  >
                    {t('header.Images')}
                  </Button>
                </ChakraLink>
                <MobileDropdown title={t('header.Recommendations')} closeMenu={closeMenu} options={[
                  { title: t('recommendations.Flights'), url: '/recommendations/flights' },
                  { title: t('recommendations.Restaurants'), url: '/recommendations/restaurants' },
                  { title: t('recommendations.Hotels'), url: '/recommendations/hotels' },
                  { title: t('recommendations.Activities'), url: '/recommendations/activities' },
                  { title: t('recommendations.Currency'), url: '/recommendations/currency' },
                ]} />
                <ChakraLink as={Link} to="/rsvp" style={buttonStyle} onClick={closeMenu}>
                  <Button
                    w="100%"
                    bgColor="pink.100"
                    color="black"
                    _hover={{ textDecoration: 'underline' }}
                    _active={{ textDecoration: 'underline' }}
                  >
                    {t('header.RSVP')}
                  </Button>
                </ChakraLink>
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Flex>
  );
};

export default MobileMenu;
