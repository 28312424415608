import { Box, Button, VStack } from '@chakra-ui/react';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';

interface Option {
  title: string;
  url: string;
}

interface MobileDropdownProps {
  title: string;
  options: Option[];
  closeMenu: () => void;
}

const MobileDropdown: FC<MobileDropdownProps> = ({ options, title, closeMenu}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box>
      <Button
        as={Button}
        bgColor="transparent"
        color="black"
        width={'100%'}
        _hover={{ textDecoration: 'underline', bgColor: 'transparent' }}
        _active={{ textDecoration: 'underline' }}
        textDecoration="none"
        onClick={toggleMenu}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        {`${isOpen ? '-' : '+'} ${title}`}
      </Button>
      {isOpen && (
        <VStack
          zIndex={5}
          border={0}
          shadow={0}
          pt={0}
          fontSize="lg"
          width={'100%'}
        >
          {options?.map((option, index) => (
            <Button
              key={index}
              as={Link}
              bgColor="transparent"
              color="black"
              width={'100%'}
              _hover={{ textDecoration: 'underline', bgColor: 'transparent' }}
              _active={{ textDecoration: 'underline' }}
              textDecoration="none"
              to={option.url}
              onClick={closeMenu}
            >
              {option.title}
            </Button>
          ))}
        </VStack>
      )}
    </Box>
  );
};

export default MobileDropdown;
