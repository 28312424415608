// GuestCard.js
import { Box, Text } from '@chakra-ui/react';
import { Guest } from '../RSVP/types';

interface GuestCardProps {
    guest: Guest;
}

const GuestCard: React.FC<GuestCardProps> = ({ guest }) => {
    return (
        <Box
            borderWidth="5px"
            borderRadius="lg"
            borderColor={guest.attendance === 'Yes' ? 'green.100' : 'red.100'}
            p={4}
            bgColor="white"
            width="100%"
            shadow="md"
        >
            <Text fontSize="xl" fontWeight="bold" noOfLines={1}>
                {guest.name}
            </Text>
            <Text noOfLines={1}>
                <strong>Attendance:</strong> {guest.attendance}
            </Text>
            {guest.message && (
                <Text>
                    <strong>Message (Optional):</strong> {guest.message}
                </Text>
            )}
        </Box>
    );
};

export default GuestCard;
