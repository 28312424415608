import React from 'react';
import { Box, Heading, Text, Wrap } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { faUtensils, faMusic, faGlassCheers, faChurch } from '@fortawesome/free-solid-svg-icons';
import ImageCard from './ImageCard';

const ImagesPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box py={{ base: 8, md: 12 }} px={{ base: 4, md: 8 }} textAlign="center">
      <Heading size="xl" mb={4} fontFamily="Poppins">
        {t('images.Title')}
      </Heading>
      <Text fontSize="lg" mb={8} fontFamily="Poppins" color="gray.600">
        {t('images.Description')}
      </Text>
      <Wrap spacing={6} justify="center">
        <ImageCard
          title={t('images.Ceremony')}
          description={t('images.CeremonyDescription')}
          icon={faChurch}
          link="https://drive.google.com/drive/folders/1djJBZsXUyAD2UAKXm74TvT4IGYDYKoRD?usp=sharing"
        />
          <ImageCard
            title={t('images.Pinchos')}
            description={t('images.PinchosDescription')}
            icon={faGlassCheers}
            link="https://drive.google.com/drive/folders/1Vy_lwfMNO9EfuJ_jimebWEaE6RdUtKsa?usp=sharing"
          />
        <ImageCard
          title={t('images.Dinner')}
          description={t('images.DinnerDescription')}
          icon={faUtensils}
          link="https://drive.google.com/drive/folders/1MXV3xySLN3xumhp2Lf93XPRS25G48R46?usp=sharing"
        />
        <ImageCard
          title={t('images.Party')}
          description={t('images.PartyDescription')}
          icon={faMusic}
          link="https://drive.google.com/drive/folders/1CBaDpLJ7cVf9Ds0VRNqx_kKMOPPtAntP?usp=sharing"
        />
      </Wrap>
    </Box>
  );
};

export default ImagesPage;
