import { Box, Container } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header/Header';

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children}) => {
  const location = useLocation();
  const isHomePage = location.pathname === '/home' || location.pathname === '/';
  return (
    <Box minH="100vh" bgColor="#f0f0f0" width="100%" >
      <Header />

      <main>
        <Container maxWidth={isHomePage ? "100%" : "85%"} minHeight={'90vh'} px={0}>
          {children}
        </Container>
      </main>

      <Footer />
    </Box>
  );
};

export default Layout;
