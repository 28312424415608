// ErrorText.js
import { Text } from '@chakra-ui/react';

interface ErrorTextProps {
    error: string;
}


const ErrorText: React.FC<ErrorTextProps> = ({ error }) => {
    return (
        <Text color="red.500" fontWeight="bold" mt={4}>
            Error: {error}
        </Text>
    );
};

export default ErrorText;
