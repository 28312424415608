import { Box, HStack, Heading, Link, Stack, Text, VStack } from "@chakra-ui/react";
import { faLocationDot, faMoneyBill1Wave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from 'react-i18next';
import { FaStar } from "react-icons/fa";
import Amenities from "./Amenities";
import { HotelInfoType } from "./types";
import PricesModal from "./HotelPrices/PricesModal";

interface HotelInfoProps {
    hotel: HotelInfoType;
}

const getStars = (stars: number | undefined) => {
    const starsArray = [];
    if (!stars) {
        return null;
    }
    for (let i = 0; i < stars; i++) {
        starsArray.push(<FaStar key={i} color="orange" size={'10'} />);
    }
    return (
        <HStack spacing={0.5} textColor={'gray.800'}>
            {starsArray}
        </HStack>
    );
}

const HotelInfo: React.FC<HotelInfoProps> = ({
    hotel
}) => {
    const { t } = useTranslation();

    return (
        <Box
            borderWidth="1px"
            borderRadius="lg"
            bgColor={'white'}
            overflow="hidden"
            boxShadow="md"
            p={4}
        >
            <Stack
                justifyContent="space-between"
                width={'100%'}
                mb={2}
                direction={{ base: "column", lg: "row" }}
                align={{ base: "flex-start", lg: "center" }}
            >
                <Stack direction={{ base: 'column', lg: 'row' }}>
                    <Heading as="h3" size="sm" fontFamily={'Poppins'}>
                        {hotel.name}
                    </Heading>
                    {getStars(hotel.stars)}
                </Stack>
                <VStack fontSize="sm" spacing={0} align={{ base: "flex-start", lg: "center" }}>
                    <HStack textColor={'gray.800'}>
                        <FaStar color="orange" />
                        <Text>
                            {hotel.rating}
                        </Text>
                    </HStack>
                    <Text fontSize={{ base: "2xs", md: "xs" }} noOfLines={1}>({hotel.reviews} {t('hotels.hotelInfo.Reviews')})</Text>
                </VStack>
            </Stack>
            <HStack justifyContent="space-between" width={'100%'}>
                <VStack alignItems={'flex-start'}>
                    <HStack>
                        <FontAwesomeIcon icon={faLocationDot} color="gray.500" />
                        <Text fontSize="sm" color="gray.800">
                            {hotel.location}
                        </Text>
                    </HStack>
                    <HStack>
                        <FontAwesomeIcon icon={faMoneyBill1Wave} color="gray.500" />
                        <Text fontSize="sm" color="gray.800">
                            {hotel.priceRange}
                        </Text>
                    </HStack>
                    <Box my={2}>
                        <Amenities amenities={hotel.amenities} />
                    </Box>
                    <Link fontSize="sm" color="blue.400" href={hotel.website} target="_blank">
                        {t('hotels.hotelInfo.VisitWebsite')}
                    </Link>
                </VStack>
                <PricesModal hotel={hotel}/>
            </HStack>
        </Box >
    );
};

export default HotelInfo;
