import React, { useState, useEffect } from 'react';
import { Box, Button, Input, Text, VStack, HStack, IconButton } from '@chakra-ui/react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

interface PasswordProtectedProps {
  children: React.ReactNode;
}

const PasswordProtected: React.FC<PasswordProtectedProps> = ({ children }) => {
  const { t } = useTranslation();
  const [inputPassword, setInputPassword] = useState<string>('');
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const correctPassword = process.env.REACT_APP_RSVP_PASS || '';
  const EXPIRY_TIME = 15 * 60 * 1000; // 15 minutes

  useEffect(() => {
    const storedAuth = sessionStorage.getItem('isAuthenticated');
    const storedTime = sessionStorage.getItem('authTime');
    
    if (storedAuth === 'true' && storedTime) {
      const currentTime = new Date().getTime();
      if (currentTime - parseInt(storedTime, 10) < EXPIRY_TIME) {
        setIsAuthenticated(true);
      } else {
        sessionStorage.removeItem('isAuthenticated');
        sessionStorage.removeItem('authTime');
      }
    }
  }, []);

  const handlePasswordSubmit = () => {
    if (inputPassword === correctPassword) {
      setIsAuthenticated(true);
      const currentTime = new Date().getTime();
      sessionStorage.setItem('isAuthenticated', 'true');
      sessionStorage.setItem('authTime', currentTime.toString());
    } else {
      alert(t('passwordProtected.IncorrectPassword'));
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  if (isAuthenticated) {
    return <>{children}</>;
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
      <VStack
        spacing={4}
        bgColor="white"
        p={{ base: 6, lg: 10 }}
        borderRadius="xl"
        width={{ base: "100%", md: "50%" }}
        boxShadow="md"
      >
        <Text>{t('passwordProtected.Message')}</Text>
        <HStack width="100%">
          <Input
            type={showPassword ? 'text' : 'password'}
            placeholder={t('passwordProtected.PasswordPlaceholder')}
            value={inputPassword}
            onChange={(e) => setInputPassword(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handlePasswordSubmit()}
            width="100%"
          />
          <IconButton
            aria-label="Toggle Password Visibility"
            icon={showPassword ? <FaEyeSlash /> : <FaEye />}
            onClick={togglePasswordVisibility}
          />
        </HStack>
        <Button colorScheme="blue" onClick={handlePasswordSubmit}>
          {t('passwordProtected.SubmitButton')}
        </Button>
      </VStack>
    </Box>
  );
};

export default PasswordProtected;
