import { HStack, Icon, Tooltip } from "@chakra-ui/react";
import {
    faChurch,
    faCocktail,
    faCoffee,
    faDumbbell,
    faFish,
    faHotTub,
    faSpa,
    faSun,
    faSwimmingPool,
    faTableTennis,
    faUtensils
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface AmenitiesProps {
    amenities: string[];
}

interface AmenitiesIcons {
    [key: string]: any;
}

const amenitiesIcons: AmenitiesIcons = {
    "Outdoor Swimming Pool": faSwimmingPool,
    "Indoor Pool": faSwimmingPool,
    "Restaurant": faUtensils,
    "Jacuzzi": faHotTub,
    "Café": faCoffee,
    "Spa": faSpa,
    "Pond": faFish,
    "Chapel": faChurch,
    "Outdoor Spaces": faSun,
    "Bar": faCocktail,
    "Fitness Center": faDumbbell,
    "Table Tennis": faTableTennis,
};

const Amenities: React.FC<AmenitiesProps> = ({ amenities }) => {


    return (
        <HStack spacing={2}>
            {amenities.map((amenity, index) => (
                <Tooltip label={amenity} key={index}>
                    <Icon
                        key={index}
                        as={FontAwesomeIcon}
                        icon={amenitiesIcons[amenity]}
                        color="gray.800"
                        fontSize="sm"
                    />
                </Tooltip>
            ))}
        </HStack>
    )
};

export default Amenities;
