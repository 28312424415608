import { useState, useEffect } from 'react';
import { Guest } from '../components/RSVP/types';


export const useRSVPApi = () => {
    const [rsvpData, setRSVPData] = useState<Guest[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
  
    const fetchRSVPs = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL ?? ''}/api/rsvp`);
        if (!response.ok) {
          throw new Error('Failed to fetch RSVP data');
        }
        const data = await response.json();
        setRSVPData(data);
        setError(null);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError('An error occurred');
        }
      } finally {
        setLoading(false);
      }
    };
  
    const createRSVP = async (guests: Guest[]) => {
        try {
          setLoading(true);
      
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL ?? ''}/api/rsvp`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(guests), // Send the array of guests
          });
      
          if (!response.ok) {
            throw new Error('Failed to create RSVP');
          }
      
          await response.json();
          setError(null);
        } catch (error) {
          if (error instanceof Error) {
            setError(error.message);
            throw new Error('Failed to create RSVP');
          } else {
            setError('An error occurred');
            throw new Error('Failed to create RSVP');
          }
        } finally {
          setLoading(false);
        }
      };
      
  
    useEffect(() => {
      if (!error) {
        // fetchRSVPs();
      }
    }, [error]);  // Add error as a dependency
  
    return {
      rsvpData,
      loading,
      error,
      fetchRSVPs,
      createRSVP,
    };
  };
  