import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Text, VStack, HStack, Spinner, Center } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Guest } from "./types";

interface RSVPConfirmationDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    rsvpData: Guest[] | null; // Receive RSVP data
    isLoading?: boolean;
}

const RSVPConfirmationDialog: React.FC<RSVPConfirmationDialogProps> = ({ isOpen, onClose, onConfirm, rsvpData, isLoading }: RSVPConfirmationDialogProps) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{t('rsvpForm.rsvpConfirmation')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {isLoading && <Center minH={'25vh'}><Spinner /></Center>}
                    {!isLoading && <Text>{t('rsvpForm.rsvpConfirmationText')}</Text>}
                    {rsvpData && rsvpData.length > 0 && !isLoading ? (
                        <VStack align="start" mt={4} >
                            {rsvpData.map((guest, index) => (
                                <VStack key={index} spacing={2} textAlign={'start'} width={"100%"} alignItems={'start'}>
                                    <HStack><Text fontWeight={'bold'}>{`${t('rsvpForm.guest')} `}</Text> <Text>{index + 1}</Text></HStack>
                                    <HStack><Text fontWeight={'bold'}>{`${t('rsvpForm.yourName')}: `}</Text> <Text>{guest.name}</Text></HStack>
                                    <HStack><Text fontWeight={'bold'}>{`${t('rsvpForm.attendance')}: `}</Text> <Text>{guest.attendance}</Text></HStack>
                                    {guest.message && <HStack><Text fontWeight={'bold'}>{`${t('rsvpForm.message')}: `}</Text> <Text>{guest.message}</Text></HStack>}
                                </VStack>
                            ))}
                        </VStack>
                    ) : (
                        <Text>{!isLoading? t('rsvpForm.noRSVPData'): ''}</Text>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="teal" onClick={onConfirm}>
                        {t('rsvpForm.closeButton')}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default RSVPConfirmationDialog;
