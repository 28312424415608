import axios from 'axios';
import { useState } from 'react';

interface Result {
    hotel_id: string;
    chk_in: string;
    chk_out: string;
    currency: string;
    rates: Rate[];
}

interface SearchResult {
    result: Result;
}

export interface Rate {
    code: string;
    name: string;
    rate: number;
    tax: number;
}

const useHotelPriceSearch = () => {
    const [result, setResult] = useState<Result | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);

    const fetchData = async (searchParams: {
        hotelId: string;
        checkInDate: string;
        checkOutDate: string;
        adults: number;
        rooms: number;
    }) => {
        setLoading(true);
        try {
            // Convert numeric values to strings
            const queryParams = new URLSearchParams({
                hotelId: searchParams.hotelId,
                checkInDate: searchParams.checkInDate,
                checkOutDate: searchParams.checkOutDate,
                adults: searchParams.adults.toString(),
                rooms: searchParams.rooms.toString(),
            }).toString();

            // Make a GET request with the query parameters
            const response = await axios.get<SearchResult>(
                `${process.env.REACT_APP_BACKEND_URL ?? ''}/api/hotels/getPrices?${queryParams}`
            ); // Replace with your actual API URL

            setResult(response?.data.result);
            setError(null);
        } catch (err) {
            setError(err as Error); // Explicitly cast err to Error
        }
        setLoading(false);
    };

    return { result, loading, error, fetchData };
};

export default useHotelPriceSearch;
