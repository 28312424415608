import { useTranslation } from 'react-i18next';
import RecommendationsBox from './RecommendationsBox';
import HotelsInfo from './HotelsInfo/HotelsInfo';
import hotelsData from './HotelsInfo/data';
import { Center, Heading, Text, Image, VStack, Box} from '@chakra-ui/react';

const Hotels = () => {
  const { t } = useTranslation();

  return (
    <RecommendationsBox title={t('hotels.Title')} description={t('hotels.Description')}>
      <Center >
        <VStack mt={'10'} >
          <Text fontSize="lg" mb={4} width={'100%'} textAlign={'center'} fontFamily={'Poppins'}>{t('hotels.HotelZone')}</Text>
          <Box maxW={'500px'} >
            <Image src="/img/hotels_zone.webp" alt="hotels zone" rounded={'3xl'} />
          </Box>
        </VStack>
      </Center>
      <Center minH={'25vh'}>
        <Heading size="xl" mb={4} width={'100%'} textAlign={'center'} fontFamily={'Poppins'}>
          WORKING ON THIS
        </Heading>
      </Center>
      <HotelsInfo hotels={hotelsData} />
    </RecommendationsBox>
  );
};

export default Hotels;
