import { Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import RestaurantsSection from './RestaurantsSection/RestaurantsSection';

const TipsToOrder = () => {
    const { t } = useTranslation();

    const orderTips = [
        { title: t('restaurants.orderTips.GetServersAttention.Title'), description: t('restaurants.orderTips.GetServersAttention.Description') },
        { title: t('restaurants.orderTips.Water.Title'), description: t('restaurants.orderTips.Water.Description') },
        { title: t('restaurants.orderTips.Bread.Title'), description: t('restaurants.orderTips.Bread.Description') },
        { title: t('restaurants.orderTips.OrderingFood.Title'), description: t('restaurants.orderTips.OrderingFood.Description') },
        { title: t('restaurants.orderTips.Pinchos.Title'), description: t('restaurants.orderTips.Pinchos.Description') },
        { title: t('restaurants.orderTips.Tapas.Title'), description: t('restaurants.orderTips.Tapas.Description') },
        { title: t('restaurants.orderTips.HalfRacion.Title'), description: t('restaurants.orderTips.HalfRacion.Description') },
        { title: t('restaurants.orderTips.Racion.Title'), description: t('restaurants.orderTips.Racion.Description') },
        { title: t('restaurants.orderTips.WhenFinished.Title'), description: t('restaurants.orderTips.WhenFinished.Description') },
        { title: t('restaurants.orderTips.Tips.Title'), description: t('restaurants.orderTips.Tips.Description') },
    ];

    return (
        <RestaurantsSection title={t('restaurants.orderTips.TipsToOrder')}>
            <VStack>
                {orderTips.map((item, index) => (
                    <VStack key={index} pt={2}>
                        <Text fontWeight="semibold" fontSize={'lg'}>{item.title}</Text>
                        <Text>{item.description}</Text>
                    </VStack>
                ))}
            </VStack>
        </RestaurantsSection>
    );
};

export default TipsToOrder;
