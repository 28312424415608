import { Box, Heading, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const RentingACar = () => {
    const { t } = useTranslation();

    return (
        <Box bg="white" p={4} py={8} rounded="xl" boxShadow="lg" width={'100%'} height={'fit-content'}>
            <Heading size="md" mb={4} width={'100%'} fontFamily={'Poppins'}>
                {t('activities.rentingACar.Title')}
            </Heading>
            <Text>
                {t('activities.rentingACar.Intro')}
            </Text>
            <Text fontWeight="semibold" mt={4}>
                {t('activities.rentingACar.ToVisitByCar')}
            </Text>
            <VStack alignItems={'baseline'} spacing={3} mt={3}>
                <Text>{t('activities.rentingACar.northOfGalicia.PlayaAsCatedrais')}</Text>
                <Text>{t('activities.rentingACar.northOfGalicia.SanAndresDeTeixido')}</Text>
                <Text>{t('activities.rentingACar.northOfGalicia.sanAndresDeTeixido.AcantiladosGaritaDeHerbeira')}</Text>
                <Text>{t('activities.rentingACar.northOfGalicia.BancoDeAloina')}</Text>
                <Text>{t('activities.rentingACar.northOfGalicia.PlayaDoForno')}</Text>
                <Text>{t('activities.rentingACar.southOfGalicia.OGrove')}</Text>
                <Text>{t('activities.rentingACar.southOfGalicia.Sanxenxo')}</Text>
                <Text>{t('activities.rentingACar.southOfGalicia.Boiro')}</Text>
                <Text>{t('activities.rentingACar.southOfGalicia.PortoDoSon')}</Text>
            </VStack>
        </Box>
    );
};

export default RentingACar;
