import { Box, Button, Heading, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Registry = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleButtonClick = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        void navigate('/rsvp');
    };

    return (
        <Box bg="white" p={4} rounded="xl" boxShadow="lg" width={'100%'}>
            <VStack spacing={8} p={{base: 2, lg: 10}}>
                <Heading fontSize={{base: "4xl", lg: '5xl'}} textAlign={'center'}>{t('contact.registry.Title')}</Heading>
                <Text fontSize={'lg'} width={{base: '100%', lg:'70%'}} textAlign={'center'}>{t('contact.registry.Description')}</Text>
                <Button colorScheme='teal' onClick={handleButtonClick}>{t('contact.registry.RSVP')}</Button>
            </VStack>
        </Box>
    );
};

export default Registry;
