import { Box, Flex, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import DesktopMenu from '../DesktopMenu';
import MobileMenu from '../MobileMenu';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Flex
            position="sticky"
            top="0"
            zIndex="999" // Adjust the z-index as needed
            align="center"
            justify="space-between"
            p={2}
            bgColor="#fefefe"
            color="black"
        >
            <Heading as="button" fontSize={{base:"3xl", lg: "4xl"}} fontWeight={'normal'} onClick={() => {navigate('/home')}}>
                {t('header.WeddingTitle')}
            </Heading>
            <Box display={['block', 'block', 'block', 'none']}>
                <MobileMenu />
            </Box>
            <Box display={['none','none', 'none', 'block']}>
                <DesktopMenu />
            </Box>
        </Flex>
    );
};

export default Header;
