import { VStack, Image, Text } from "@chakra-ui/react";
import { useState, useEffect } from "react";

const LoadingScreen = () => {
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        if (isLoading) {
            document.body.style.overflow = "hidden";
            document.body.style.height = "100%";
          }
          if (!isLoading) {
            document.body.style.overflow = "auto";
            document.body.style.height = "auto";
          }

      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
    }, [isLoading]);

    if (!isLoading) {
        return null;
    }

  
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "fixed",
                top: 0,
                backgroundColor: 'white',
                left: 0,
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                zIndex: 9999999999999,
            }}
        >
            <VStack>
                <Image
                    src={'/logo512.png'}
                    alt="Logo"
                    boxSize="100px"
                    sx={{
                        "@keyframes logoSpin": {
                            from: {
                                transform: "rotate(0deg)",
                            },
                            to: {
                                transform: "rotate(360deg)",
                            },
                        },
                        "@keyframes logoMove": {
                            from: {
                                transform: "translate3d(0, 0, 0)",
                            },
                            to: {
                                transform: "translate3d(0, -10px, 0)",
                            },
                        },
                        animation: "logoSpin 1s linear infinite, logoMove 1s ease-in-out infinite alternate",
                    }}
                />
                <Text fontSize={'xl'}>Katelyn & Álvaro</Text>
            </VStack>
        </div>);
}

export default LoadingScreen;