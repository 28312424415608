import { Box, BoxProps, Center, Heading, Text } from '@chakra-ui/react';
import { FC } from 'react';

interface RecommendationsBoxProps extends BoxProps {
    title: string;
    description: string;
    children?: React.ReactNode;
}

const RecommendationsBox: FC<RecommendationsBoxProps> = ({ title, description, children, ...props }) => {

    return (
        <Box px={{ base: 0, md: 6 }} py={{ base: 4, md: 6 }} width={"100%"} {...props}>
            <Heading size="lg" mb={4} width={'100%'} textAlign={'center'} fontFamily={'Poppins'}>
                {title}
            </Heading>
            <Center>
                <Text fontSize="md" mb={4} width={{ base: '100%', md: '80%' }} textAlign={'center'} fontFamily={'Poppins'}>
                    {description}
                </Text>
            </Center>
            {children}
        </Box>
    );
};

export default RecommendationsBox;
