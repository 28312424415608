import React from 'react';
import { Box, Center, Heading, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import StorySection from './StorySection';
import StoryItem from './StoryItem';

const OurStory: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Box p={{ base: 0, md: 6 }} mx={{ base: 4, md: 10 }} mt={4} textAlign={'center'}>
            <Heading textAlign="center" mb={4} fontSize={{ base: '5xl', md: '6xl' }} fontWeight={'normal'}>{t('homepage.ourStory.Title')}</Heading>
            <StoryItem>
                <StorySection
                    title={t('homepage.ourStory.HowWeMet')}
                    content={t('homepage.ourStory.HowWeMetContent')}
                />
            </StoryItem>
            <StoryItem>
                <Center>
                    <Image
                        src="img/ourStory/image8.webp"
                        alt="Server Image"
                        draggable={false}
                        maxHeight="350px"
                    />
                </Center>
            </StoryItem>
            <StoryItem>
                <StorySection
                    title={t('homepage.ourStory.FavoriteMemories')}
                    content={t('homepage.ourStory.FavoriteMemoriesContent')}
                />
            </StoryItem>
            <StoryItem>
                <Center>
                    <Image
                        src="img/ourStory/image7.webp"
                        alt="Server Image"
                        draggable={false}
                        maxHeight="350px"
                    />
                </Center>
            </StoryItem>
            <StoryItem>
                <StorySection
                    title={t('homepage.ourStory.OurFamily')}
                    content={t('homepage.ourStory.OurFamilyContent')}
                />
            </StoryItem>
            <StoryItem>
                <Center>
                    <Image
                        src="img/ourStory/image2.webp"
                        alt="Server Image"
                        draggable={false}
                        maxHeight="350px"
                    />
                </Center>
            </StoryItem>
            <StoryItem>
                <StorySection
                    title={t('homepage.ourStory.OurProposal')}
                    content={t('homepage.ourStory.OurProposalContent')}
                />
            </StoryItem>
        </Box>
    );
};

export default OurStory;
