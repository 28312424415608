import {
    VStack
  } from '@chakra-ui/react';
import Registry from './Contact/Registry';
import ContactInfo from './Contact/ContactInfo';
  
  const ContactPage = () => {
    return (
      <VStack py={4} spacing={6}>
        <Registry />
        <ContactInfo />
      </VStack>
    );
  };
  
  export default ContactPage;
  