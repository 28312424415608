import { ChakraProvider, CSSReset, extendTheme } from '@chakra-ui/react';
import '@fontsource-variable/playfair-display';
import '@fontsource/alex-brush';
import "@fontsource/caveat/700.css";
import "@fontsource/kalam/";
import '@fontsource/poppins';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ContactPage from './components/ContactPage';
import DashboardPage from './components/DashboardPage';
import HomePage from './components/HomePage';
import InformationPage from './components/InformationPage';
import Layout from './components/Layout/Layout';
import ScrollToTop from './components/Layout/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';
import Activities from './components/Recommendations/Activities/Activities';
import Flights from './components/Recommendations/Flights';
import Hotels from './components/Recommendations/Hotels';
import Restaurants from './components/Recommendations/Restaurants/Restaurants';
import RecommendationsPage from './components/RecommendationsPage';
import RSVPPage from './components/RSVPPage';
import Currency from './components/Recommendations/Currency/Currency';
import PasswordProtected from './components/PasswordProtected';
import ImagesPage from './components/Images/ImagesPage';

const theme = extendTheme({
  fonts: {
    heading: `Alex Brush, Arial, sans-serif`,
    body: `Playfair Display Variable, sans-serif`,
  },
  styles: {
    global: {
      body: {
        bg: 'white',
      },
    },
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <LoadingScreen />
      <CSSReset />
      <Router>
        <ScrollToTop />
        <Layout>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/info" element={<InformationPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/rsvp" element={<RSVPPage />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/recommendations" element={<RecommendationsPage />} />
            <Route path="/recommendations/flights" element={<Flights />} />
            <Route path="/recommendations/restaurants" element={<Restaurants />} />
            <Route path="/recommendations/hotels" element={<Hotels />} />
            <Route path="/recommendations/activities" element={<Activities />} />
            <Route path="/recommendations/currency" element={<Currency />} />
            <Route path="/images" element={<PasswordProtected><ImagesPage /></PasswordProtected>} />
          </Routes>
        </Layout>
      </Router>
    </ChakraProvider>
  );
}

export default App;
