import {
  VStack
} from '@chakra-ui/react';
import DateAndPlace from './Information/DateAndPlace';
import MappAndAddress from './Information/MapAndAddress';

const InformationPage = () => {
  return (
    <VStack py={4} spacing={6}>
      <DateAndPlace />
      <MappAndAddress />
      {/* <Gallery /> */}
    </VStack>
  );
};

export default InformationPage;
