import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { Link } from 'react-router-dom';

interface RecommendationCardProps {
    title: string;
    icon: JSX.Element; // Use React.ElementType for icon
    description: string;
    linkTo: string;
}

const RecommendationCard: FC<RecommendationCardProps> = ({ title, icon, description, linkTo }) => {
    return (
        <Box bg="white" p={4} rounded="xl" boxShadow="lg" width={{ base: '100%', lg: '48%' }} as={Link} to={linkTo} transition="all 0.3s" _hover={{ transform: "scale(1.02)",shadow: "2xl",}}>
            <HStack spacing={4}>
                {icon}
                <VStack alignItems="flex-start">
                    <Text fontSize="xl" fontWeight="bold">
                        {title}
                    </Text>
                    <Text fontSize="md">{description}</Text>
                </VStack>
            </HStack>
        </Box>
    );
};

export default RecommendationCard;
