import { Box, Heading, Stack, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Iframe from 'react-iframe';

const MappAndAddress = () => {
    const { t } = useTranslation();

    return (
        <Box bg="white" p={4} rounded="xl" boxShadow="lg" width={'100%'}>
            <Heading size="md" mb={4} width={'100%'} textAlign={'center'} fontFamily={'Poppins'}>{t('information.mapAndAddress.Title')}</Heading>
            <Stack direction={{base: "column-reverse", lg: 'row'}} spacing={4}>
                    <Box width={{base: '100%', lg:'50%'}}>
                        <Iframe
                            url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2902.235642963487!2d-8.360859524001297!3d43.33025787325821!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd2e65a186c4d527%3A0x115e31b28fe42e74!2sPazo%20do%20R%C3%ADo!5e0!3m2!1ses!2sus!4v1695230056938!5m2!1ses!2sus"
                            width="100%"
                            height="300"
                            scrolling="no"
                            allowFullScreen
                        ></Iframe>
                    </Box>
                    <VStack width={{base: '100%', lg:'50%'}} textAlign={'center'} pt={4} spacing={4}>
                        <VStack>
                            <Text fontSize={'lg'} fontWeight={'bold'}>{t("information.mapAndAddress.Address")}</Text>
                            <Text>{t("information.mapAndAddress.AddressText")}</Text>
                        </VStack>
                        <VStack>
                            <Text fontSize={'lg'} fontWeight={'bold'}>{t("information.mapAndAddress.HowToGetThere")}</Text>
                            <Text>{t("information.mapAndAddress.WorkingOnIt")}</Text>
                        </VStack>
                    </VStack>
            </Stack>
        </Box>
    );
};

export default MappAndAddress;
