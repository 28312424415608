/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Heading, Stack, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Countdown from './Home/Countdown';
import OurStory from './Home/OurStory';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import ConfirmAssistance from './Home/ConfirmAttendance';
import ScrollForMore from './Home/ScrollForMore';


const HomePage: React.FC = () => {
  const { i18n,t } = useTranslation();
  const targetDate = new Date('2024-06-08T17:00:00');

  const textShadowStyle = {
    textShadow: '8px 8px 10px rgba(0, 0, 0, 0.8)', // Increased shadow values
  };

  return (
    <Box>
      <Box
        minH={"94vh"}
        display="flex"
        justifyContent="center"
        color="black"
        textAlign="center"
        alignItems={{ base: "none", lg: "none" }}
        width="100%"
        bgImage={'url(/img/homepage.webp)'}
        bgColor={'gray.800'}
        backgroundSize="cover"
        backgroundPosition="center"
      >
        <VStack textColor="white" height="100%">
          <Stack height="100%" mt={{ base: 28, md: 10 }}>
            <Heading fontSize={{ base: "5xl", lg: "8xl" }} textColor="white" style={textShadowStyle} fontWeight={'normal'}>
              {t('homepage.Names')}
            </Heading>
          </Stack>
          <VStack spacing={4} mt={4}>
            <Text fontSize={{ base: "2xl", lg: "4xl" }} style={textShadowStyle}>
              {t('homepage.Married')}
            </Text>
            <Text fontSize={{ base: "4xl", lg: "6xl" }} style={textShadowStyle} fontFamily={'Alex Brush'} mx={3}>
              {t('homepage.Date')}
            </Text>
            <Text fontSize={{ base: "4xl", lg: "6xl" }} style={textShadowStyle} fontFamily={'Alex Brush'} mx={3}>
              {t('homepage.Location')}
            </Text>
          </VStack>
          <Box mt={6}>
            <Countdown targetDate={targetDate} />
          </Box>
          <AddToCalendarButton
            name="Katelyn &amp; Alvaro's Wedding"
            startDate="2024-06-08"
            startTime='17:00'
            endTime='23:00'
            lightMode='dark'
            timeZone='CET'
            language={i18n.language === 'es' ? 'es' : 'en'}
            options={['Apple', 'Google']}
          ></AddToCalendarButton>
        </VStack>
      </Box>
      <ConfirmAssistance />
      <OurStory />
      <ScrollForMore />
    </Box>
  );
};

export default HomePage;
