import { Button, Link as ChakraLink, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import LanguageSelector from '../Home/LanguageSelector';
import Dropdown from './Header/Dropdown/Dropdown';

const DesktopMenu = () => {
  const { t } = useTranslation();
  const location = useLocation();

  // A reusable function to create a button with specified properties
  const createButton = (to: string, label: string, hasColor?: boolean) => (
    <ChakraLink as={Link} to={to}>
      <Button
        mr={2}
        bgColor={hasColor ? 'pink.100' : 'white'}
        fontSize={'lg'}
        fontFamily={'Poppins'}
        textUnderlineOffset={'0.2em'}
        transition={'all 0.3s'}
        textDecoration={location?.pathname.includes(to) ? 'underline' : 'none'}
        _hover={{
          textDecoration: 'underline',
          transform: "scale(1.02)",
        }}
        _active={{
          textDecoration: 'underline',
        }}
      >
        {label}
      </Button>
    </ChakraLink>
  );

  return (
    <Flex align="center" py={4}>
      {createButton('/home', t('header.Home'))}
      {createButton('/info', t('header.Info'))}
      {createButton('/contact', t('header.Contact'))}
      {createButton('/images', t('header.Images'), )}
      <Dropdown
        title={t('header.Recommendations')}
        url={'/recommendations'}
        options={[
          { title: t('recommendations.Flights'), url: '/recommendations/flights' },
          { title: t('recommendations.Restaurants'), url: '/recommendations/restaurants' },
          { title: t('recommendations.Hotels'), url: '/recommendations/hotels' },
          { title: t('recommendations.Activities'), url: '/recommendations/activities' },
          { title: t('recommendations.Currency'), url: '/recommendations/currency' },
        ]}
        redirectUrl={'/recommendations'}
      />
      {createButton('/rsvp', t('header.RSVP'), true)}
      <LanguageSelector />
    </Flex>
  );
};

export default DesktopMenu;
