import React, { FC, useState } from "react";
import { Button, FormControl, FormLabel, HStack, Input, Stack, Text, VStack, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { HotelInfoType } from "../types";

interface PricesInputFieldsProps {
  hotel: HotelInfoType;
  onSearch: (searchParams: {
    hotelId: string;
    checkInDate: string;
    checkOutDate: string;
    adults: number;
    rooms: number;
  }) => void;
}

const PricesInputFields: FC<PricesInputFieldsProps> = ({ onSearch, hotel }) => {
  const { t } = useTranslation(); // i18n translation function

  const [checkInDate, setCheckInDate] = useState("");
  const [checkOutDate, setCheckOutDate] = useState("");
  const [adults, setAdults] = useState(1);
  const [rooms, setRooms] = useState(1);
  const toast = useToast();
  const hotelId = hotel.id;

  const handleSearch = () => {
    // Add date validation
    if (validateDates(checkInDate, checkOutDate)) {
      onSearch({ hotelId, checkInDate, checkOutDate, adults, rooms });
    } else {
      toast({
        title: "Invalid dates",
        description: "Please check your dates and try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const validateDates = (checkIn: string, checkOut: string): boolean => {
    const today = new Date().toISOString().split("T")[0];
    return checkIn >= today && checkOut > checkIn;
  };

  return (
    <VStack spacing={4}>
      <VStack direction={{ base: 'column', lg: 'row' }} fontSize={{ base: 'md', md: 'sm' }} width="100%" p={4} spacing={4}>
        <Stack direction={{ base: "column", md: 'row' }} spacing={4} width={'100%'}>
          <VStack textAlign="start" width={'100%'}>
            <FormControl>
              <FormLabel top='-24px' left={0} zIndex={2} position='absolute' backgroundColor='white' pointerEvents='none' mx='3' px='1' my={3} fontSize='sm'>{t("hotels.hotelInfo.pricesInputFields.CheckinDateLabel")}</FormLabel>
              <Input
                type="date"
                value={checkInDate}
                onChange={(e) => setCheckInDate(e.target.value)}
                size="md"
                fontSize={'sm'}
              />
            </FormControl>
          </VStack>
          <VStack textAlign="start" width={'100%'}>
            <FormControl>
              <FormLabel top='-24px' left={0} zIndex={2} position='absolute' backgroundColor='white' pointerEvents='none' mx='3' px='1' my={3} fontSize='sm'>{t("hotels.hotelInfo.pricesInputFields.CheckoutDateLabel")}</FormLabel>
              <Input
                type="date"
                width={'100%'}
                value={checkOutDate}
                onChange={(e) => setCheckOutDate(e.target.value)}
                size="md"
                fontSize={'sm'}
              />
            </FormControl>

          </VStack>
        </Stack>
        <HStack width={'100%'}>
          <VStack width="100%" textAlign="start">
            <FormControl>
              <FormLabel top='-24px' left={0} zIndex={2} position='absolute' backgroundColor='white' pointerEvents='none' mx='3' px='1' my={3} fontSize='sm'>{t("hotels.hotelInfo.pricesInputFields.AdultsLabel")}</FormLabel>
              <Input
                type="number"
                value={adults}
                textAlign={'center'}
                onChange={(e) => setAdults(parseInt(e.target.value))}
                size="md"
                fontSize={'sm'}
                min={1}
                max={32}
              />
            </FormControl>
          </VStack>
          <VStack width="100%" textAlign="start">
            <FormControl width={'100%'}>
              <FormLabel top='-24px' left={0} zIndex={2} position='absolute' backgroundColor='white' pointerEvents='none' mx='3' px='1' my={3} fontSize='sm'>{t("hotels.hotelInfo.pricesInputFields.RoomsLabel")}</FormLabel>
              <Input
                type="number"
                textAlign={'center'}
                value={rooms}
                onChange={(e) => setRooms(parseInt(e.target.value))}
                size="md"
                min={1}
                max={8}
              />
            </FormControl>
          </VStack>
        </HStack>
      </VStack>
      <Button onClick={handleSearch} colorScheme="teal">
        {t("hotels.hotelInfo.pricesInputFields.SearchButtonLabel")}
      </Button>
    </VStack>
  );
};

export default PricesInputFields;
