import { VStack } from '@chakra-ui/react';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

const ScrollForMore: React.FC = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrolled = window.scrollY > 600;
            setIsScrolled(scrolled);
        };

        // Attach the event listener when the component mounts
        window.addEventListener('scroll', handleScroll);

        // Detach the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <VStack
            as={motion.div}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: isScrolled ? 0 : 1, y: isScrolled ? 0 : 10 }}
            transition={{ duration: 0.5, type: 'spring', stiffness: 120 } as any}
            position="fixed"
            bottom="6"
            right="4"
            spacing="1"
            color="teal.400"
            zIndex={9999}
            sx={{
                "@keyframes logoMove": {
                    from: {
                        transform: "translate3d(0, 0, 0)",
                    },
                    to: {
                        transform: "translate3d(0, -10px, 0)",
                    },
                },
                animation: "logoSpin 1s linear infinite, logoMove 1s ease-in-out infinite alternate",
            }}
        >
            <FontAwesomeIcon icon={faArrowDown} size="2x" />
        </VStack>
    );
};

export default ScrollForMore;
