import { Center, Text, VStack } from '@chakra-ui/react';
import React from 'react';

interface StorySectionProps {
    title: string;
    content: string;
}


const StorySection: React.FC<StorySectionProps> = ({ title, content }) => (
    <Center>
        <VStack width={'100%'}>
            <Text fontSize="xl" fontWeight="bold" mt={4}>
                {title}
            </Text>
            <Text mt={2}>{content}</Text>
        </VStack>
    </Center>
);

export default StorySection;
