import { AbsoluteCenter, Box, Divider, Text, VStack } from "@chakra-ui/react"

interface Item {
    name: string,
    description?: string
}

interface TypicalDrinksSectionProps {
    items: Item[]
    title: string
}

const TypicalDrinksSection: React.FC<TypicalDrinksSectionProps> = ({ items, title }) => {
    return (
        <VStack width={'100%'} py={4}>
            <Box position='relative' padding={2} width={{base: '100%', lg: '80%'}}>
                <Divider width={'100%'} />
                <AbsoluteCenter bgColor={'white'} px={4}>
                    <Text fontWeight="semibold" fontSize={'lg'} >{title}</Text>
                </AbsoluteCenter>
            </Box>
            <VStack spacing={4}>
                {items.map((item, index) => (
                    <VStack spacing={'1px'}>
                            <Text key={index}>{item.name}</Text>
                            {item.description ? <Text fontSize={'xs'}>{item.description}</Text> : null}
                    </VStack>
                ))}
            </VStack>
        </VStack>
    )
}

export default TypicalDrinksSection