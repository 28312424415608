import { Box, Stack, Text, VStack } from '@chakra-ui/react';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faChampagneGlasses, faChurch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const DateAndPlace = () => {
  const { t } = useTranslation();
  return (
    <Box bg="white" p={6} rounded="xl" boxShadow="lg" width={'100%'} textAlign={'center'}>
      <Stack width={'100%'} minHeight={'200'} direction={{ base: 'column', md: "row" }} alignItems={'center'} spacing={10}>
        <VStack width={'50%'} spacing={4}>
          <FontAwesomeIcon icon={faCalendar} size='4x' />
          <VStack spacing={0}>
            <Text fontSize={'xl'} fontWeight={'bold'}>{t('information.dateAndPlace.OurWeddingDay')}</Text>
            <Text fontSize={'lg'}>{t('information.dateAndPlace.Day')}</Text>
            <Text fontSize={'lg'}>{t('information.dateAndPlace.Year')}</Text>
          </VStack>
        </VStack>
        <VStack width={'50%'} spacing={4}>
          <FontAwesomeIcon icon={faChurch} size='4x' />
          <VStack spacing={0}>
            <Text fontSize={'xl'} fontWeight={'bold'}>{t('information.dateAndPlace.Ceremony')}</Text>
            <Text fontSize={'lg'}>{t('information.dateAndPlace.PazoDoRio')}</Text>
            <Text fontSize={'lg'}>{t('information.dateAndPlace.HourWedding')}</Text>
          </VStack>
        </VStack>
        <VStack width={'50%'} spacing={4}>
          <FontAwesomeIcon icon={faChampagneGlasses} size='4x' />
          <VStack spacing={0}>
            <Text fontSize={'xl'} fontWeight={'bold'}>{t('information.dateAndPlace.Reception')}</Text>
            <Text fontSize={'lg'}>{t('information.dateAndPlace.PazoDoRio')}</Text>
            <Text fontSize={'lg'}>{t('information.dateAndPlace.HourReception')}</Text>
          </VStack>
        </VStack>
      </Stack>
    </Box>
  );
};

export default DateAndPlace;
